import { ApiResponse } from '@/models/ApiResponse';
import { ActionContext } from 'vuex';
import { AuthenticationData } from '@/models/AuthenticationData'
import { AppleSignInAuthResponse } from '@/models/AppleSignInAuthResponse'
import { LoginResponse } from '@/models/LoginResponse'
import { ProfileResponse } from '@/models/ProfileResponse'
import { User } from '@/models/User'
import { State } from '.';
import api from '@/api'
import { ForgotPasswordData } from '../models/ForgotPasswordData';

/**
 * Authentication state interface
 * Stores the JWT token and current user information
 */
export interface AuthState {
  /** JSON Web Token for authenticated API requests */
  jwt: string;
  /** Current authenticated user or null if not logged in */
  user: User | null;
}

/** Type alias for the Vuex action context */
type Context = ActionContext<AuthState, State>;

/**
 * Auth Module for Vuex store
 * Handles authentication state, login/logout, and user profile
 */
export default {
  state: {
    jwt: '',
    user: null
  },
  mutations: {
    /**
     * Sets the JWT token and stores it in localStorage for persistence
     */
    setJwt: function(state: AuthState, jwt: string) {
      state.jwt = jwt
      try {
        if (jwt) {
          localStorage.setItem('jwt', jwt);
        } else {
          localStorage.removeItem('jwt');
        }
      } catch {
        console.log('Local storage is not available.')
      }
    },
    /**
     * Sets the current user in the state
     */
    setUser: function(state: AuthState, user: User) {
      state.user = user
    }
  },
  actions: {
    /**
     * Initializes the auth state from localStorage (if available)
     * Fetches user profile if JWT exists
     */
    init: async function(context: Context) {
      const jwt = localStorage.getItem('jwt')
      if (jwt) {
        context.commit('setJwt', jwt);
        await context.dispatch('profile');
      }
    },
    
    /**
     * Authenticates user with email/password credentials
     * Sets JWT and loads user profile on successful login
     */
    login: async function (context: Context, data: AuthenticationData): Promise<ApiResponse<LoginResponse>> {
        const response = await api.login(data);
        if (response.isError === false) {
            context.commit('setJwt', response.result.jwt);
            await context.dispatch('profile');
        }
        return response;
    },

    /**
     * Authenticates user with Apple Sign-in
     * Sets JWT and loads user profile on successful login
     */
    apple: async function (context: Context, data: AppleSignInAuthResponse): Promise<ApiResponse<LoginResponse>> {
        const response = await api.apple(data);
        if (response.isError === false) {
            context.commit('setJwt', response.result.jwt);
            await context.dispatch('profile');
        }
        return response;
    },

    /**
     * Sends forgot password request to the API
     */
    forgotpassword: async function (context: Context, data: ForgotPasswordData): Promise<string> {
        const response = await api.forgotpassword(data);
        return response;
    },

    /**
     * Fetches the current user profile from the API
     * Updates the user state on success
     */
    profile: async function (context: Context): Promise<User | null> {
      try {
        const user = await api.profile(context.state.jwt);
        context.commit('setUser', user);
      return user;
      } catch {
        return null;
      }
    },

    /**
     * Updates user profile information
     * Updates the user state on success
     */
    updateProfile: async function (context: Context, user: User): Promise<ProfileResponse> {
      const response  = await api.updateProfile(user, context.state.jwt);
      if (response.success)
        context.commit('setUser', user);
      return response;
    }
  },
}