/**
 * Enum representing the level of detail for 3D model processing
 * Higher detail levels result in more accurate but larger/slower-to-process models
 */
export enum DetailType {
    /** Lowest detail level - fastest processing time, lowest quality */
    Preview = 0,
    
    /** Lower detail level with some optimization */
    Reduced = 1,
    
    /** Balanced detail level suitable for most use cases */
    Medium = 2,
    
    /** High detail level with minimal optimization */
    Full = 3,
    
    /** Maximum detail with no optimization - largest file size */
    Raw = 4,
    
    /** User-defined custom detail settings */
    Custom = 5
}