/**
 * API Client Module
 * 
 * This module provides a centralized client for all API interactions with the backend.
 * It includes:
 * - Authenticated and unauthenticated API requests
 * - Date handling for request/response
 * - Data transformation functions
 * - Functions for all API endpoints grouped by feature
 * 
 * API endpoints are organized into functional areas:
 * - Authentication (login, register, password management)
 * - User Management
 * - Project Management
 * - Job Management
 * - File Upload/Processing
 * - Tag/Role Management
 * 
 * All requests that require authentication accept a JWT token parameter.
 * Most responses are wrapped in an ApiResponse<T> type for consistent error handling.
 */
import axios from 'axios';
import { ApiResponse } from '@/models/ApiResponse';
import { ProfileResponse } from '@/models/ProfileResponse';
import { ChangePasswordData } from '@/models/ChangePasswordData';
import { AuthenticationData } from '@/models/AuthenticationData';
import { AppleSignInAuthResponse } from '@/models/AppleSignInAuthResponse';
import { RegistrationData } from '@/models/RegistrationData';
import { ForgotPasswordData } from '@/models/ForgotPasswordData';
import { LoginResponse } from '@/models/LoginResponse';
import { User } from '@/models/User';
import { Project } from '@/models/Project';
import { ProjectUpload } from '@/models/ProjectUpload';
import { FileRequestResponse } from '@/models/FileRequestResponse';
import { Job } from '@/models/Job';
import { JobRunner } from '@/models/JobRunner';
import { ContentResponse } from '@/models/ContentResponse';
import { JobActivityRecord } from '@/models/JobActivityRecord';
import { NerfRequestData } from '@/models/NerfRequestData';
import { CustomFormData } from '@/models/CustomFormData';
import { ProjectFinishData } from '@/models/ProjectFinishData';
import { JobFilterRequestModel } from '@/models/JobFilterRequestModel';
import { JobFilterResponseModel } from '@/models/JobFilterResponseModel';
import { ProjectFilterRequestModel } from '@/models/ProjectFilterRequestModel';
import { ProjectFilterResponseModel } from '@/models/ProjectFilterResponseModel';
import { JobCropRequestData } from '@/models/JobCropRequestData';
import { ProjectCropRequestData } from '@/models/ProjectCropRequestData';
import { InitProjectUploadRequestData } from '@/models/InitProjectUploadRequestData';
import { InitProjectUploadResponseData } from '@/models/InitProjectUploadResponseData';
import { Tag } from '@/models/Tag';
import { ProjectTag } from '@/models/ProjectTag';
import { Role } from '@/models/Role';

/**
 * Regular expression to identify ISO date strings in the response
 */
const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;

/**
 * JSON reviver function that converts ISO date strings to Date objects
 * Used when parsing API responses
 */
function reviver(key: string, value: string) {
  if (dateFormat.test(value)) {
    return new Date(value);
  }
  return value;
}

/**
 * Transforms API response by parsing JSON and converting dates
 * Returns null for empty responses
 */
function transformResponse(response: string) {
  if (response && response.trim()) {
    return JSON.parse(response, reviver);
  }
  return null;
}

/**
 * Creates the Axios request configuration with authentication header and response transformation
 * @param jwt JSON Web Token for authentication
 */
function config(jwt: string) {
  return {
    headers: { Authorization: `Bearer ${jwt}` },
    transformResponse: transformResponse,
  };
}

/**
 * Creates the Axios request configuration for file uploads
 * Sets the proper Content-Type header for multipart form data
 * @param jwt JSON Web Token for authentication
 */
function configForFileUpload(jwt: string) {
  return {
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'multipart/form-data',
    },
    transformResponse: transformResponse,
  };
}

/**
 * Converts a User object to DTO format for API requests
 * Ensures only the expected properties are sent to the API
 */
function userToDto(user: User): User {
  return {
    id: user.id,
    created: user.created,
    updated: user.updated,
    userName: user.userName,
    companyName: user.companyName,
    email: user.email,
    isAdmin: user.isAdmin,
    isActive: user.isActive,
    invitationToken: user.invitationToken,
    credit: user.credit,
    appleUserId: user.appleUserId,
    totalProject: user.totalProject,
    runnerId: user.runnerId,
    runnerName: user.runnerName,
  };
}

/**
 * Converts a Project object to DTO format for API requests
 * Ensures only the expected properties are sent to the API
 */
function projectToDto(project: Project): Project {
  return {
    id: project.id,
    created: project.created,
    updated: project.updated,
    name: project.name,
    objectKey: project.objectKey,
    thumbnail: project.thumbnail,
    type: project.type,
    status: project.status,
    height: project.height,
    amountOfImages: project.amountOfImages,
    userName: project.userName,
    isMaxResolutionImageSet: project.isMaxResolutionImageSet,
    detail: project.detail,
    message: project.message,
    captureMode: project.captureMode,
    projectTags: project.projectTags,
    isSelected: project.isSelected,
  };
}

/**
 * Converts a Job object to DTO format for API requests
 * Ensures only the expected properties are sent to the API
 */
function jobToDto(job: Job): Job {
  return {
    id: job.id,
    created: job.created,
    message: job.message,
    urls: job.urls,
    progress: job.progress,
    userName: job.userName,
    jobRunnerName: job.jobRunnerName,
    projectName: job.projectName,
    projectType: job.projectType,
    status: job.status,
    height: job.height,
    processingDuration: job.processingDuration,
    frameProcessingDuration: job.frameProcessingDuration,
    amountOfImages: job.amountOfImages,
    thumbnail: job.thumbnail,
    isMaxResolutionImageSet: job.isMaxResolutionImageSet,
    detail: job.detail,
    captureMode: job.captureMode,
    projectTags: job.projectTags,
    isSelected: job.isSelected,
  };
}

const api = {
  login: async function (
    data: AuthenticationData
  ): Promise<ApiResponse<LoginResponse>> {
    let response: any;
    await axios
      .post('authentication/login', data)
      .then((apiresponse) => {
        response = apiresponse.data;
      })
      .catch((reason) => (response = reason.response.data));
    return response;
  },
  apple: async function (
    data: AppleSignInAuthResponse
  ): Promise<ApiResponse<LoginResponse>> {
    let response: any;
    await axios
      .post('authentication/apple', data)
      .then((apiresponse) => {
        response = apiresponse.data;
      })
      .catch((reason) => (response = reason.response.data));
    return response;
  },
  getInvitedUser: async function (invitationToken: string): Promise<User> {
    const response = await axios.get(`registration/${invitationToken}`);
    return response.data.result;
  },
  register: async function (
    invitationToken: string,
    data: RegistrationData
  ): Promise<User> {
    const response = await axios.post(`registration/${invitationToken}`, data);
    return response.data.result;
  },
  verifyemail: async function (emailVerifiicationToken: string): Promise<User> {
    const response = await axios.get(
      `registration/verifyemail/${emailVerifiicationToken}`
    );
    return response.data.result;
  },
  forgotpassword: async function (data: ForgotPasswordData): Promise<string> {
    const response = await axios.post('authentication/forgotpassword', data);
    return response.data.result;
  },

  profile: async function (jwt: string): Promise<User> {
    const response = await axios.get('profile', config(jwt));
    return response.data.result;
  },

  changePassword: async function (
    data: ChangePasswordData,
    jwt: string
  ): Promise<void> {
    const response = await axios.post(
      'profile/change-password',
      data,
      config(jwt)
    );
    return response.data.result;
  },
  getUsers: async function (jwt: string): Promise<Array<User>> {
    const response = await axios.get('users', config(jwt));
    return response.data.result;
  },
  getUser: async function (id: number, jwt: string): Promise<User> {
    const response = await axios.get(`users/${id}`, config(jwt));
    return response.data.result;
  },
  addUser: async function (user: User, jwt: string): Promise<User> {
    const response = await axios.post('users', userToDto(user), config(jwt));
    return response.data.result;
  },
  updateUser: async function (user: User, jwt: string): Promise<User> {
    const response = await axios.put(
      `users/${user.id}`,
      userToDto(user),
      config(jwt)
    );
    return response.data.result;
  },
  updateUserCredit: async function (user: User, jwt: string): Promise<User> {
    const response = await axios.put(
      'users/update-user-credit',
      userToDto(user),
      config(jwt)
    );
    return response.data.result;
  },
  updateUserRunner: async function (user: User, jwt: string): Promise<User> {
    const response = await axios.put(
      'users/update-user-runner',
      userToDto(user),
      config(jwt)
    );
    return response.data.result;
  },
  deleteUser: async function (user: User, jwt: string): Promise<void> {
    const response = await axios.delete(`users/${user.id}`, {
      ...config(jwt),
      data: { Id: user.id },
    });
    return response.data.result;
  },
  getProjects: async function (jwt: string): Promise<Array<Project>> {
    const response = await axios.get('projects', config(jwt));
    return response.data.result;
  },

  getProjectsByFilter: async function (
    requestData: ProjectFilterRequestModel,
    jwt: string
  ): Promise<ProjectFilterResponseModel> {
    const response = await axios.post(
      'projects/get-projects-by-filter',
      requestData,
      config(jwt)
    );
    return response.data.result;
  },
  addProject: async function (project: Project, jwt: string): Promise<Project> {
    const response = await axios.post(
      'projects',
      projectToDto(project),
      config(jwt)
    );
    return response.data.result;
  },
  //projectUploads: async function (project: Project, jwt: string): Promise<ProjectUpload> {
  //    const response = await axios.post(`projectUploads/${project.id}`, null, config(jwt));
  //    return response.data.result;
  //},

  uploadImageInitProcess: async function (
    initProjectUploadRequestData: InitProjectUploadRequestData,
    jwt: string
  ): Promise<ApiResponse<InitProjectUploadResponseData>> {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    };

    const response = await axios.post(
      'projectUploads/init_upload_proccess',
      initProjectUploadRequestData,
      config
    );
    return response.data.result;
  },

  uploadZipInitProcess: async function (
    initProjectUploadRequestData: InitProjectUploadRequestData,
    jwt: string
  ): Promise<ApiResponse<InitProjectUploadResponseData>> {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    };

    const response = await axios.post(
      'projectUploads/init_upload_zip_proccess',
      initProjectUploadRequestData,
      config
    );
    return response.data.result;
  },

  uploadImage: async function (
    projectUpload: ProjectUpload,
    jwt: string
  ): Promise<boolean> {
    const postRequests = projectUpload.files.map((file) => {
      axios
        .post(
          `projectUploads/${projectUpload.projectId}/upload/${projectUpload.id}/${file.fileName}`,
          file.file,
          configForFileUpload(jwt)
        )
        .catch((error) => console.log('ERROR: ' + error));
    });
    await axios.all(postRequests);
    return true;
  },

  updateProfile: async function (
    user: User,
    jwt: string
  ): Promise<ProfileResponse> {
    const response = await axios.put(
      `/profile?id=${user.id}`,
      userToDto(user),
      config(jwt)
    );
    return response.data.result;
  },

  finishAndRun: async function (
    requestData: ProjectFinishData,
    jwt: string
  ): Promise<void> {
    const response = await axios.post(
      `projectUploads/${requestData.projectId}/finish_upload_proccess/${requestData.projectUploadId}?detail=${requestData.detail}&useObjectMask=${requestData.useObjectMask}&featureSensitivity=${requestData.featureSensitivity}`,
      null,
      config(jwt)
    );
    return response.data.result;
  },

  finishAndRunWithBounding: async function (
    requestData: ProjectFinishData,
    jwt: string
  ): Promise<void> {
    const response = await axios.post(
      `projectUploads/${requestData.projectId}/finish_zip_upload_proccess`,
      requestData,
      config(jwt)
    );
    return response.data.result;
  },

  updateProject: async function (
    project: Project,
    jwt: string
  ): Promise<Project> {
    const response = await axios.put(
      `projects/${project.id}`,
      projectToDto(project),
      config(jwt)
    );
    return response.data.result;
  },
  updateProjectStatus: async function (
    project: Project,
    jwt: string
  ): Promise<Project> {
    const response = await axios.put(
      'projects/update-project-status',
      projectToDto(project),
      config(jwt)
    );
    return response.data.result;
  },
  deleteProject: async function (project: Project, jwt: string): Promise<void> {
    const response = await axios.delete(`projects/${project.id}`, config(jwt));
    return response.data.result;
  },
  deleteAllProject: async function (jwt: string): Promise<void> {
    const response = await axios.delete(
      'projects/delete-all-project',
      config(jwt)
    );
    return response.data.result;
  },

  deleteSelectedProject: async function (
    projectIds: string,
    jwt: string
  ): Promise<void> {
    const url = `projects/delete-selected-project/${projectIds}`;
    const response = await axios.delete(url, config(jwt));
    return response.data.result;
  },

  deleteSelectedJob: async function (
    jobIds: string,
    jwt: string
  ): Promise<void> {
    const url = `jobs/delete-selected-jobs/${jobIds}`;
    const response = await axios.delete(url, config(jwt));
    return response.data.result;
  },

  downloadProject: async function (
    project: Project,
    jwt: string
  ): Promise<Array<ContentResponse>> {
    const response = await axios.get(`projects/${project.id}`, config(jwt));
    return response.data.result;
  },
  convertProject: async function (
    project: Project,
    detail: number,
    objectMask: boolean,
    jwt: string
  ): Promise<void> {
    await axios.post(
      `jobs/${project.id}?detail=${detail}&useObjectMask=${objectMask}`,
      null,
      config(jwt)
    );
  },

  getJobs: async function (jwt: string): Promise<Array<Job>> {
    const response = await axios.get('jobs', config(jwt));
    return response.data.result;
  },
  getJobsByFilter: async function (
    requestData: JobFilterRequestModel,
    jwt: string
  ): Promise<JobFilterResponseModel> {
    const response = await axios.post(
      'jobs/get-jobs-by-filter',
      requestData,
      config(jwt)
    );
    return response.data.result;
  },

  downloadJob: async function (
    job: Job,
    jwt: string
  ): Promise<Array<ContentResponse>> {
    const response = await axios.get(`jobs/${job.id}`, config(jwt));
    return response.data.result;
  },
  finaldownloadJob: async function (
    job: Job,
    jwt: string
  ): Promise<Array<ContentResponse>> {
    const response = await axios.get(`jobs/${job.id}/finalmodel`, config(jwt));
    return response.data.result;
  },
  updateJobStatus: async function (job: Job, jwt: string): Promise<Job> {
    const response = await axios.put(
      'jobs/update-job-status',
      jobToDto(job),
      config(jwt)
    );
    return response.data.result;
  },
  nerfsubmit: async function (
    job: Job,
    nerfdata: NerfRequestData,
    jwt: string
  ) {
    await await axios.post(`jobs/${job.id}/nerf`, nerfdata, config(jwt));
  },
  getJobRunners: async function (jwt: string): Promise<Array<JobRunner>> {
    const response = await axios.get('runners', config(jwt));
    return response.data.result;
  },
  getActiveJobRunners: async function (jwt: string): Promise<Array<JobRunner>> {
    const response = await axios.get('runners/getactiverunners', config(jwt));
    return response.data.result;
  },
  activate: async function (
    jwt: string,
    runner: JobRunner
  ): Promise<Array<JobRunner>> {
    const response = await axios.post(
      `runners/${runner.id}/activate`,
      null,
      config(jwt)
    );
    return response.data.result;
  },
  deactivate: async function (
    jwt: string,
    runner: JobRunner
  ): Promise<Array<JobRunner>> {
    const response = await axios.post(
      `runners/${runner.id}/deactivate`,
      null,
      config(jwt)
    );
    return response.data.result;
  },
  updateRunnerName: async function (
    jwt: string,
    runner: JobRunner
  ): Promise<Array<JobRunner>> {
    const response = await axios.put(
      'runners/updateRunnerName',
      runner,
      config(jwt)
    );
    return response.data.result;
  },
  resubmit: async function (job: Job, jwt: string) {
    await axios.post(`jobs/${job.id}/resubmit`, null, config(jwt));
  },
  deleteJob: async function (job: Job, jwt: string): Promise<void> {
    const response = await axios.delete(`jobs/${job.id}`, config(jwt));
    return response.data.result;
  },

  croppingJob: async function (
    job: Job,
    croppingData: JobCropRequestData,
    jwt: string
  ): Promise<void> {
    const response = await axios.post(
      `jobs/${job.id}/cropping`,
      croppingData,
      config(jwt)
    );
    return response.data.result;
  },
  croppingProject: async function (
    project: Project,
    croppingData: ProjectCropRequestData,
    jwt: string
  ): Promise<void> {
    const response = await axios.post(
      `projects/${project.id}/cropping`,
      croppingData,
      config(jwt)
    );
    return response.data.result;
  },

  deleteJobRunner: async function (
    jobRunner: JobRunner,
    jwt: string
  ): Promise<void> {
    const response = await axios.delete(`runners/${jobRunner.id}`, config(jwt));
    return response.data.result;
  },
  pingJobRunner: async function (
    jobRunner: JobRunner,
    jwt: string
  ): Promise<void> {
    const response = await axios.get(
      `runners/${jobRunner.id}/ping`,
      config(jwt)
    );
    return response.data.result;
  },
  resubmitToRunner: async function (job: Job, runner: JobRunner, jwt: string) {
    await axios.post(`jobs/${job.id}/${runner.id}/resubmit`, null, config(jwt));
  },
  resubmitProjectToRunner: async function (
    project: Project,
    runner: JobRunner,
    jwt: string
  ) {
    await axios.post(
      `projects/${project.id}/${runner.id}/resubmit`,
      null,
      config(jwt)
    );
  },
  getJobActivityRecords: async function (
    jwt: string,
    jobId: number
  ): Promise<Array<JobActivityRecord>> {
    const response = await axios.get(`jobs/${jobId}/log`, config(jwt));
    return response.data.result;
  },
  getExpectedbRunnerVersion: async function (jwt: string): Promise<number> {
    const response = await axios.get('runners/expected-version', config(jwt));
    return response.data.version;
  },
  getUrlFromKey: async function (key: string): Promise<User> {
    const response = await axios.get(`shorturl/${key}`);
    return response.data;
  },
  getTags: async function (jwt: string): Promise<Array<Tag>> {
    const response = await axios.get('tags', config(jwt));
    return response.data.result;
  },
  getTag: async function (id: number, jwt: string): Promise<Tag> {
    const response = await axios.get(`tags/${id}`, config(jwt));
    return response.data.result;
  },
  addTag: async function (tag: Tag, jwt: string): Promise<Tag> {
    const response = await axios.post('tags', tag, config(jwt));
    return response.data.result;
  },
  updateTag: async function (tag: Tag, jwt: string): Promise<Tag> {
    const response = await axios.put(`tags/${tag.id}`, tag, config(jwt));
    return response.data.result;
  },
  deleteTag: async function (tag: Tag, jwt: string): Promise<void> {
    const response = await axios.delete(`tags/${tag.id}`, config(jwt));
    return response.data.result;
  },
  getRoles: async function (jwt: string): Promise<Array<Role>> {
    const response = await axios.get('roles', config(jwt));
    return response.data.result;
  },
  getRole: async function (id: number, jwt: string): Promise<Role> {
    const response = await axios.get(`roles/${id}`, config(jwt));
    return response.data.result;
  },
  addRole: async function (role: Role, jwt: string): Promise<Role> {
    const response = await axios.post('roles', role, config(jwt));
    return response.data.result;
  },
  updateRole: async function (role: Role, jwt: string): Promise<Role> {
    const response = await axios.put(`roles/${role.id}`, role, config(jwt));
    return response.data.result;
  },
  deleteRole: async function (role: Role, jwt: string): Promise<void> {
    const response = await axios.delete(`roles/${role.id}`, config(jwt));
    return response.data.result;
  },
  addProjectTag: async function (projectTag: ProjectTag, jwt: string): Promise<ProjectTag> {
    const response = await axios.post('projecttags', projectTag, config(jwt));
    return response.data.result;
  },
  deleteProjectTag: async function (projectTag: ProjectTag, jwt: string): Promise<void> {
    const response = await axios.delete(`projecttags/${projectTag.tagId}/${projectTag.projectId}`, config(jwt));
    return response.data.result;
  }
};

export default api;
