import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = {
  key: 0,
  class: "alert alert-danger",
  role: "alert"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[5] || (_cache[5] = _createElementVNode("p", null, "Please set your password:", -1)),
    _createElementVNode("form", null, [
      _createElementVNode("div", _hoisted_2, [
        _cache[3] || (_cache[3] = _createElementVNode("label", {
          for: "password",
          class: "form-label"
        }, "Password", -1)),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event)),
          type: "password",
          class: "form-control",
          id: "password"
        }, null, 512), [
          [_vModelText, _ctx.password]
        ]),
        _createElementVNode("span", null, _toDisplayString(_ctx.passwordError), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _cache[4] || (_cache[4] = _createElementVNode("label", {
          for: "password2",
          class: "form-label"
        }, "Confirm Password", -1)),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password2) = $event)),
          type: "password",
          class: "form-control",
          id: "password2",
          rules: "confirmed:@password"
        }, null, 512), [
          [_vModelText, _ctx.password2]
        ]),
        _createElementVNode("span", null, _toDisplayString(_ctx.passwordError2), 1)
      ]),
      (_ctx.errorMsg)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.errorMsg), 1))
        : _createCommentVNode("", true),
      _createElementVNode("input", {
        class: "btn btn-primary",
        type: "button",
        value: "Submit",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
      })
    ])
  ]))
}