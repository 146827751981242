import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "header-bg"
}
const _hoisted_2 = { class: "header-container" }
const _hoisted_3 = { class: "btns" }
const _hoisted_4 = {
  class: "theme-switches",
  style: {"display":"none"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.store.state.auth.user)
    ? (_openBlock(), _createElementBlock("header", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_router_link, { to: "/" }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createElementVNode("img", {
                class: "main-logo__icon white-v",
                src: "/images/logo-icon-text.png"
              }, null, -1),
              _createElementVNode("img", {
                class: "main-logo__icon black-v",
                src: "/images/logo-icon-text-black-v.png"
              }, null, -1)
            ])),
            _: 1
          }),
          _createElementVNode("nav", null, [
            _createVNode(_component_router_link, {
              class: "page-link__text",
              to: "/home"
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Home")
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, {
              class: "page-link__text",
              to: "/projects"
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("Projects")
              ])),
              _: 1
            }),
            (_ctx.store.state.auth.user?.isAdmin)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  class: "page-link__text",
                  to: "/jobs"
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("Jobs")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.store.state.auth.user?.isAdmin)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  class: "page-link__text",
                  to: "/users"
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode("Users")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.store.state.auth.user?.isAdmin)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 2,
                  class: "page-link__text",
                  to: "/runners"
                }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode("Job Runners")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", {
                class: "main-btn dark-theme switch-btn btn",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('switchdarktheme')))
              }, _cache[9] || (_cache[9] = [
                _createElementVNode("svg", {
                  class: "icon",
                  viewBox: "0 0 25 26",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }, [
                  _createElementVNode("path", {
                    d: "M23.975 19.8779C20.8875 19.8779 15.7624 18.2154 13.1248 13.0904C10.2123 7.45293 12.4998 2.49043 13.7873 0.50293C7.08721 0.74043 1.72461 6.22793 1.72461 12.9904C1.72461 13.1654 1.74961 13.3404 1.74961 13.5154C2.51212 13.1904 3.34964 12.9904 4.22465 12.9904C4.22465 9.12793 6.38719 5.77793 9.59975 4.11543C8.97474 6.85293 8.92474 10.4154 10.8998 14.2404C12.8623 18.0404 16.1249 20.4279 19.3999 21.5654C17.8624 22.4904 16.0874 23.0029 14.2373 23.0029C13.6123 23.0029 12.9873 22.9404 12.3873 22.8279C11.9248 23.7029 11.2123 24.4154 10.3373 24.8779C11.5623 25.2779 12.8748 25.5029 14.2248 25.5029C18.5999 25.5029 22.45 23.2529 24.6875 19.8529C24.475 19.8654 24.225 19.8779 23.975 19.8779Z",
                    fill: "white"
                  }),
                  _createElementVNode("path", {
                    d: "M8.00014 17.9905H7.77513C7.25012 16.5405 5.8751 15.4905 4.25007 15.4905C2.17503 15.4905 0.5 17.1655 0.5 19.2405C0.5 21.3155 2.17503 22.9905 4.25007 22.9905C5.02508 22.9905 7.36262 22.9905 8.00014 22.9905C9.37516 22.9905 10.5002 21.8655 10.5002 20.4905C10.5002 19.1155 9.37516 17.9905 8.00014 17.9905Z",
                    fill: "white"
                  })
                ], -1)
              ])),
              _createElementVNode("button", {
                class: "second-btn light-theme switch-btn btn",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('switchlighttheme')))
              }, _cache[10] || (_cache[10] = [
                _createElementVNode("svg", {
                  class: "icon",
                  viewBox: "0 0 28 29",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }, [
                  _createElementVNode("path", {
                    d: "M7.45013 6.05171L5.20009 3.81421L3.43756 5.57671L5.6751 7.81421L7.45013 6.05171ZM0.25 13.1267H4.00007V15.6267H0.25V13.1267ZM12.7502 0.689209H15.2503V4.37671H12.7502V0.689209ZM22.8004 3.80796L24.5604 5.56671L22.3229 7.80421L20.5641 6.04421L22.8004 3.80796ZM20.5504 22.7017L22.7879 24.9517L24.5504 23.1892L22.3004 20.9517L20.5504 22.7017ZM24.0004 13.1267H27.7505V15.6267H24.0004V13.1267ZM14.0002 6.87671C9.86267 6.87671 6.50011 10.2392 6.50011 14.3767C6.50011 18.5142 9.86267 21.8767 14.0002 21.8767C18.1378 21.8767 21.5004 18.5142 21.5004 14.3767C21.5004 10.2392 18.1378 6.87671 14.0002 6.87671ZM14.0002 19.3767C11.2377 19.3767 9.00016 17.1392 9.00016 14.3767C9.00016 11.6142 11.2377 9.37671 14.0002 9.37671C16.7628 9.37671 19.0003 11.6142 19.0003 14.3767C19.0003 17.1392 16.7628 19.3767 14.0002 19.3767ZM12.7502 24.3767H15.2503V28.0642H12.7502V24.3767ZM3.43756 23.1767L5.20009 24.9392L7.43763 22.6892L5.6751 20.9267L3.43756 23.1767Z",
                    fill: "white"
                  })
                ], -1)
              ]))
            ]),
            _createVNode(_component_router_link, { to: "/profile" }, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createElementVNode("img", {
                  class: "avatar-user",
                  src: "/images/avatar-user.png"
                }, null, -1)
              ])),
              _: 1
            }),
            _createElementVNode("div", {
              class: "hamburger",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('hamburgerClicked')))
            }, _cache[12] || (_cache[12] = [
              _createElementVNode("span", null, null, -1),
              _createElementVNode("span", null, null, -1)
            ]))
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}