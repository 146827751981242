import { useStore } from 'vuex';
    import { State } from '../store';
    import { Job } from '../models/Job';
    import { Project } from '../models/Project';
    import { ProjectType } from '../models/ProjectType';
    import { CaptureMode } from '../models/CaptureMode';
    import { ProjectStatus } from '../models/ProjectStatus';
    import { DetailType } from '../models/DetailType';
    import {
        defineComponent,
        reactive,
        ref,
        watch,
        version,
        computed,
        onMounted,
    } from 'vue';
    import { inject } from 'vue';
    import * as signalR from '@microsoft/signalr';
    import api from '../api';
    import { useRouter } from 'vue-router';
    import { ModelViewerElement } from '@google/model-viewer';
    import { useLoading } from 'vue-loading-overlay';
    import { JobRunner } from '../models/JobRunner';
    import { ProjectCropRequestData } from '../models/ProjectCropRequestData';
   
    import StatusIcon from '../components/StatusIcon.vue';
    import RangeList from '../components/RangeList.vue';
    import RUSureModal from '../components/RUSureModal.vue';
    import { Tag } from '../models/Tag';
    import { ProjectTag } from '../models/ProjectTag';

    export default defineComponent({
        components: {
            StatusIcon,
            RangeList,
            RUSureModal,
        },
        data: function () {
            return {
                minHeight: 0,
                maxHeight: 1000,
            };
        },
        emits: [
            'selectedJob',
            'showModal',
            'showModal1',
            'showDetails',
            'hideDetails',
            'modalViewerUrl',
        ],
        methods: {
            editViewValue(newSize: string) {
                document.documentElement.style.setProperty(
                    '--font-size-body',
                    `${newSize}vw`
                );
            },
        },
        setup(props, context) {
            const store = useStore<State>();
            store.dispatch('users/getUsers');

            store.dispatch('tags/getTags');

            const getEnumValues = (enumObj: any) => {
                return Object.keys(enumObj)
                    .filter((key) => isNaN(Number(key)))
                    .map((key) => ({ value: enumObj[key], label: key }));
            };

            const loading = useLoading({
                // options
            });

            const searchkeyword = ref('');

            const sortingKey = ref('');
            let sortDescending = false;

            let filter_userId = 0;

            const allJobs_HasMoreJobs = ref(false);
            const allJobs = ref(Array<Job>());

            const projectStatus = ProjectStatus;
            const captureMode = CaptureMode;
            const projectType = ProjectType;
            const router = useRouter();
            const selectedJob = ref<Job>();
            const detailType = DetailType;
            const showModal = ref(false);
            const showModal1 = ref(false);
            const modalViewerUrl = ref<string>();

            const runners = ref(Array<JobRunner>());

            //const loader = useLoading();
            ModelViewerElement.meshoptDecoderLocation =
                'https://cdn.jsdelivr.net/npm/meshoptimizer/meshopt_decoder.js';

            //store.dispatch('jobs/getJobs');
            const project_layout = 'layout_project';
            const layout = ref<'table' | 'boxes'>('table');

            const setLayout = (newLayout: 'table' | 'boxes') => {
                layout.value = newLayout;
                localStorage.setItem(project_layout, newLayout);
            };
            const getButtonClass = (buttonLayout: 'table' | 'boxes') => {
                return layout.value === buttonLayout
                    ? 'main-btn projects-users__switch-btn btn'
                    : 'second-btn projects-users__switch-btn btn';
            };
            const layoutClass = computed(() => {
                return layout.value === 'table'
                    ? 'projects-layout'
                    : 'projects-layout layout-boxes';
            });

            const checkedStatusindivisual = ref([]);
            const selectedJobIds = ref<number[]>([]);
            const selectedJobsNum = ref(0);
            const allJobsCheckBoxState = ref(false);
            const selectAllJobsCheckBoxState = ref(false);

            const filter_detail = ref<number[]>([]);
            const filter_minHeight = ref(0);
            const filter_maxHeight = ref(1000);
            watch(
                () => filter_minHeight.value,
                (newValue) => {
                    filter_minHeight.value = Math.min(newValue, filter_maxHeight.value - 5);
                }
            );
            watch(
                () => filter_maxHeight.value,
                (newValue) => {
                    filter_maxHeight.value = Math.max(newValue, filter_minHeight.value + 5);
                }
            );

            const minInputValue = 0;
            const maxInputValue = 1000;
            const filter_tags = ref<Tag[]>([]);
            const filter_status = ref<ProjectStatus[]>([]);

            const isDropdownOpen = ref(false);
            let closeTimeout: ReturnType<typeof setTimeout> | null = null;

            function toggleDropdown() {
                isDropdownOpen.value = !isDropdownOpen.value;
                if (isDropdownOpen.value) {
                    closeTimeout = setTimeout(() => {
                        closeDropdown();
                    }, 5000);
                }
            }

            function closeDropdown() {
                isDropdownOpen.value = false;
                if (closeTimeout !== null) {
                    clearTimeout(closeTimeout);
                    closeTimeout = null;
                }
            }

            const selectAllJobs = () => {
                allJobs.value.forEach((job) => {
                    job.isSelected = !selectAllJobsCheckBoxState.value;
                });
                selectAllJobsCheckBoxState.value =
                    !selectAllJobsCheckBoxState.value;
                updateSelectedJobsNum();
                showBrowsBar.value = !showBrowsBar.value;
            };

            const selectJob = (job: Job) => {
                job.isSelected = !job.isSelected;
                updateSelectedJobsNum();
                showBrowsBar.value = !showBrowsBar.value;
            };

            const unselectAllJobs = () => {
                allJobs.value.forEach((job) => {
                    job.isSelected = false;
                });
                selectAllJobsCheckBoxState.value = false;
                selectedJobsNum.value = 0;
            };

            const updateSelectedJobsNum = () => {
                selectedJobsNum.value = allJobs.value.filter(
                    (job) => job.isSelected
                ).length;
            };

            const selectJobforedit = (job: Job) => {
                selectedJob.value = job;
                context.emit('selectedJob');
                return true;
            };

            const jobsPerPage = ref(10);
            const currentPage = ref(1);

            const totalPages = ref(1);

            const toggleJobsPerPage = () => {
                currentPage.value = 1;
                loadJobs();
                unselectAllJobs();
            };

            const changeSortingOption = (sortKey: string) => {
                sortingKey.value = sortKey;
                currentPage.value = 1;
                sortDescending = !sortDescending;
                loadJobs();
            };

            const changePage = (page: number) => {
                if (page >= 1 && page <= totalPages.value) {
                    currentPage.value = page;
                    loadJobs();
                }
            };

            const prevPage = () => {
                if (currentPage.value > 1) {
                    currentPage.value--;
                    loadJobs();
                }
            };

            const nextPage = () => {
                if (currentPage.value < totalPages.value) {
                    currentPage.value++;
                    loadJobs();
                }
            };

            const isEditViewModalOpen = ref(false);
            const isFilterViewModalOpen = ref(false);
            const isStutasModalOpen = ref(false);
            const isQualityModalOpen = ref(false);
            const isHeightModalOpen = ref(false);
            const isTagModalOpen = ref(false);
            const hasConfigs = true;
            const isProjectNameVisible = ref(true);
            const isUserVisible = ref(true);
            const isTypeVisible = ref(true);
            const isStatusVisible = ref(true);
            const isHeightVisible = ref(true);
            const isQualityVisible = ref(true);
            const isProcessingDurationVisible = ref(true);
            const isTagVisible = ref(true);
            const showBrowsBar = ref(true);
            // Functions to open and close modal
            const showProjectName = () => {
                isProjectNameVisible.value = true;
            };

            const hideProjectName = () => {
                isProjectNameVisible.value = false;
            };

            const toggleProjectName = (event: any) => {
                if (event.target.checked) {
                    showProjectName();
                } else {
                    hideProjectName();
                }
            };

            const showUser = () => {
                isUserVisible.value = true;
            };

            const hideUser = () => {
                isUserVisible.value = false;
            };

            const toggleUser = (event: any) => {
                if (event.target.checked) {
                    showUser();
                } else {
                    hideUser();
                }
            };

            const showType = () => {
                isTypeVisible.value = true;
            };

            const hideType = () => {
                isTypeVisible.value = false;
            };

            const toggleType = (event: any) => {
                if (event.target.checked) {
                    showType();
                } else {
                    hideType();
                }
            };

            const showStatus = () => {
                isStatusVisible.value = true;
            };

            const hideStatus = () => {
                isStatusVisible.value = false;
            };

            const toggleStatus = (event: any) => {
                if (event.target.checked) {
                    showStatus();
                } else {
                    hideStatus();
                }
            };

            const showHeight = () => {
                isHeightVisible.value = true;
            };

            const hideHeight = () => {
                isHeightVisible.value = false;
            };

            const toggleHeight = (event: any) => {
                if (event.target.checked) {
                    showHeight();
                } else {
                    hideHeight();
                }
            };

            const showQuality = () => {
                isQualityVisible.value = true;
            };

            const hideQuality = () => {
                isQualityVisible.value = false;
            };

            const toggleQuality = (event: any) => {
                if (event.target.checked) {
                    showQuality();
                } else {
                    hideQuality();
                }
            };




            const showProcessingDuration = () => {
                isProcessingDurationVisible.value = true;
            };

            const hideProcessingDuration = () => {
                isProcessingDurationVisible.value = false;
            };

            const toggleProcessingDuration = (event: any) => {
                if (event.target.checked) {
                    showProcessingDuration();
                } else {
                    hideProcessingDuration();
                }
            };

            const showTag = () => {
                isTagVisible.value = true;
            };

            const hideTag = () => {
                isTagVisible.value = false;
            };

            const toggleTag = (event: any) => {
                if (event.target.checked) {
                    showTag();
                } else {
                    hideTag();
                }
            };

            const copyToClipboard = (url: string) => {
              navigator.clipboard.writeText(url).then(() => {
                alert('Copied to clipboard!');
              }).catch(err => {
                console.error('Failed to copy: ', err);
              });
            }

            /* this ignored and all related functions */
            const currentValueofTable = ref(0.7);
            const currentValueofHeader = ref(1.5);
            const list = 'list';
            const step = 0.01;
            const min = 0.68;
            const max = 1.25;
            const valuesAndLabels = [
                { value: 0.68, label: 'Aa' },
                { value: 0.87, label: 'Aa' },
                { value: 1.06, label: 'Aa' },
                { value: 1.25, label: 'Aa' },
            ];

            const updateFontSize = (event: any) => {
                currentValueofTable.value = parseFloat(event.target.value);
                currentValueofHeader.value = parseFloat(event.target.value);
            };

            // Functions to open and close modal
            const openEditViewModal = () => {
                isEditViewModalOpen.value = true;
            };

            const closeEditViewModal = () => {
                isEditViewModalOpen.value = false;
            };

            const openFilterViewModal = () => {
                isFilterViewModalOpen.value = true;
            };

            const closeFilterModal = () => {
                isFilterViewModalOpen.value = false;
            };

            const toggleFiltersStutasModal = () => {
                isStutasModalOpen.value = !isStutasModalOpen.value;
            };

            const toggleHeightModal = () => {
                isHeightModalOpen.value = !isHeightModalOpen.value;
            };

            const toggleQualityModal = () => {
                isQualityModalOpen.value = !isQualityModalOpen.value;
            };

            const toggleFiltersTagModal = () => {
                isTagModalOpen.value = !isTagModalOpen.value;
            };

            const displayedJobs = computed(() => {
                const startIndex = (currentPage.value - 1) * jobsPerPage.value;
                const endIndex = startIndex + jobsPerPage.value;
                return allJobs.value.slice(startIndex, endIndex);
            });

            const navigateToNewJob = () => {
                router.push({ name: 'newjob' });
            };

            const errorMsg = ref('');

            const projectStatusNameList = getEnumValues(ProjectStatus);
            const quantityList = getEnumValues(DetailType);

            const projectStatusNames = {
                [ProjectStatus.InProgress]: 'In Progress',
                [ProjectStatus.ReadyToOptimize]: 'Ready to Optimize',
                [ProjectStatus.Optimizing]: 'Optimizing',
                [ProjectStatus.ReadyToPurchase]: 'Ready to Purchase',
                [ProjectStatus.Purchased]: 'Purchased',
            };

            const getProjectStatusName = (status: ProjectStatus): string => {
                return projectStatusNames[status] || 'Unknown';
            };

            // Add helper function to extract percentage from message
            const getProgressPercentage = (message: string): string | null => {
              // Check if message contains a percentage
              const percentMatch = message.match(/(\d+)%/);
              if (percentMatch && percentMatch[1]) {
                return percentMatch[1] + '%';
              }
              return null;
            };

            const connection = inject<signalR.HubConnection>('connection');
            if (connection) {
                connection.on('refresh', loadJobs);
                //connection.on('jobrefresh', RefreshJob)
            }

            function clearsearch(event: any) {
                searchkeyword.value = '';
                loadJobs();
            }

            function search(event: any) {
                if (event.target.value.length > 0) {
                    searchkeyword.value = event.target.value;
                    loadJobs();
                } else {
                    clearsearch(event);
                }
                return true;
            }

            function searchByUser(event: any) {
                if (event.target.value.length > 0) {
                    filter_userId = event.target.value;
                    currentPage.value = 1;
                    loadJobs();
                } else {
                    clearsearch(event);
                }
                return true;
            }

            async function loadJobs() {
                api
                    .getJobsByFilter(
                        {
                            userId: filter_userId,
                            searchKeyword: searchkeyword.value,
                            pageSize: jobsPerPage.value,
                            pageNo: currentPage.value,
                            sortKey: sortingKey.value,
                            sortDescending: sortDescending,
                            detail: filter_detail.value,
                            status: filter_status.value,
                            minHeight: filter_minHeight.value,
                            maxHeight: filter_maxHeight.value,
                            tags: filter_tags.value,
                        },
                        store.state.auth.jwt
                    )
                    .then((data) => {
                        (allJobs.value = data.jobs),
                            (allJobs_HasMoreJobs.value = data.hasMoreJobs);
                        totalPages.value = Math.ceil(
                            data.totalJobs / jobsPerPage.value
                        );
                    });
            }

            SetEditViewConfig();
            loadJobs();
            getRunners();

            function setItem<bool>(key: string, value: bool): void {
                localStorage.setItem(key, JSON.stringify(value));
            }

            function getItem<bool>(key: string): bool | null {
                const item = localStorage.getItem(key);
                return item ? (JSON.parse(item) as bool) : null;
            }

            function ApplyEditViewConfig() {
                closeEditViewModal();

                setItem('currentValueofTable', currentValueofTable.value);
                setItem('currentValueofHeader', currentValueofHeader.value);
                setItem('isProjectNameVisible', isProjectNameVisible.value);
                setItem('isUserVisible', isUserVisible.value);
                setItem('isTypeVisible', isTypeVisible.value);
                setItem('isStatusVisible', isStatusVisible.value);
                setItem('isHeightVisible', isHeightVisible.value);
                setItem('isQualityVisible', isQualityVisible.value);
                setItem('isProcessingDurationVisible', isProcessingDurationVisible.value);
                setItem('isTagVisible', isTagVisible.value);
            }

            function ResetEditViewConfig() {
                currentValueofTable.value = 0.7;
                currentValueofHeader.value = 1.5;
                isProjectNameVisible.value = true;
                isUserVisible.value = true;
                isTypeVisible.value = true;
                isStatusVisible.value = true;
                isHeightVisible.value = true;
                isQualityVisible.value = true;
                isProcessingDurationVisible.value = true;
                isTagVisible.value = true;

                setItem('currentValueofTable', currentValueofTable.value);
                setItem('currentValueofHeader', currentValueofHeader.value);
                setItem('isProjectNameVisible', isProjectNameVisible.value);
                setItem('isUserVisible', isUserVisible.value);
                setItem('isTypeVisible', isTypeVisible.value);
                setItem('isStatusVisible', isStatusVisible.value);
                setItem('isHeightVisible', isHeightVisible.value);
                setItem('isQualityVisible', isQualityVisible.value);
                setItem('isProcessingDurationVisible', isProcessingDurationVisible.value);
                setItem('isTagVisible', isTagVisible.value);
            }

            function SetEditViewConfig() {
                const tmp_currentValueofTable = getItem<number>('currentValueofTable');
                currentValueofTable.value =
                    tmp_currentValueofTable !== null ? tmp_currentValueofTable : 0.7;

                const tmp_currentValueofHeader = getItem<number>('currentValueofHeader');
                currentValueofHeader.value =
                    tmp_currentValueofHeader !== null ? tmp_currentValueofHeader : 1.5;

                const tmp_isProjectNameVisible = getItem<boolean>('isProjectNameVisible');
                isProjectNameVisible.value = tmp_isProjectNameVisible
                    ? tmp_isProjectNameVisible
                    : true;

                const tmp_isUserVisible = getItem<boolean>('isUserVisible');
                isUserVisible.value = tmp_isUserVisible ? tmp_isUserVisible : true;

                const temp_isTypeVisible = getItem<boolean>('isTypeVisible');
                isTypeVisible.value = temp_isTypeVisible ? temp_isTypeVisible : true;

                const temp_isStatusVisible = getItem<boolean>('isStatusVisible');
                isStatusVisible.value = temp_isStatusVisible
                    ? temp_isStatusVisible
                    : true;

                const temp_isHeightVisible = getItem<boolean>('isHeightVisible');
                isHeightVisible.value = temp_isHeightVisible
                    ? temp_isHeightVisible
                    : true;

                const tmp_isQualityVisible = getItem<boolean>('isQualityVisible');
                isQualityVisible.value = tmp_isQualityVisible
                    ? tmp_isQualityVisible
                    : true;


                const tmp_isProcessingDurationVisible = getItem<boolean>('isProcessingDurationVisible');
                isProcessingDurationVisible.value = tmp_isProcessingDurationVisible
                    ? tmp_isProcessingDurationVisible
                    : true;

                const tmp_isTagVisible = getItem<boolean>('isTagVisible');
                isTagVisible.value = tmp_isTagVisible ? tmp_isTagVisible : true;
            }

            interface CheckboxFilter {
                type: 'checkbox';
                name: string;
                number: number;
            }
            interface RangeFilter {
                type: 'range';
                name: string;
                minHeight: number;
                maxHeight: number;
            }

            type Filter = CheckboxFilter | RangeFilter;

            const aplliedFilters = ref<Filter[]>([]);

            function ApplyFilter() {
                aplliedFilters.value = [
                    {
                        type: 'checkbox',
                        name: 'status',
                        number: filter_status.value.length,
                    },
                    {
                        type: 'range',
                        name: 'height',
                        minHeight: filter_minHeight.value,
                        maxHeight: filter_maxHeight.value,
                    },
                    {
                        type: 'checkbox',
                        name: 'quality',
                        number: filter_detail.value.length,
                    },
                ];

                currentPage.value = 1;
                loadJobs();
                getRunners();
                closeFilterModal();
            }

            watch(
                () => selectedJobsNum.value,
                (newValue) => {
                    if (newValue === allJobs.value.length) {
                        selectAllJobsCheckBoxState.value = true;
                    } else {
                        selectAllJobsCheckBoxState.value = false;
                    }
                }
            );

            const apliedFilersBar = computed(() => {
                if (aplliedFilters.value.length > 0) {
                    const aplliedFiltersNumber = aplliedFilters.value.filter((filter) => {
                        if (filter.type === 'checkbox') {
                            return filter.number > 0; // Checkbox filter with at least one selected option
                        } else if (filter.type === 'range') {
                            return filter.minHeight > 0 || filter.maxHeight < 1000; // Range filter with non-default height range
                        } else {
                            return false; // Other types of filters, if any
                        }
                    }).length;

                    return aplliedFiltersNumber > 0;
                }

                return false; // No applied filters
            });

            const currentMainBar = computed(() => {
                if (selectedJobsNum.value > 0) {
                    return 'selected-bar';
                } else if (apliedFilersBar.value) {
                    return 'filters-applied-bar';
                } else {
                    return 'brows-bar';
                }
            });

            function resetSpecificFilter(filter: string) {
                if (filter === 'status') {
                    filter_status.value = [];
                } else if (filter === 'quality') {
                    filter_detail.value = [];
                } else if (filter === 'height') {
                    filter_minHeight.value = 0;
                    filter_maxHeight.value = 1000;
                }
                currentPage.value = 1;
                loadJobs();
                getRunners();
                ApplyFilter();
            }

            function ResetFilter() {
                filter_detail.value = [];
                filter_minHeight.value = 0;
                filter_maxHeight.value = 1000;
                filter_tags.value = [];
                filter_status.value = [];
                currentPage.value = 1;
                loadJobs();
                getRunners();
                ApplyFilter();
            }

            function reset() {
                //currentPage.value = 1;
                //loadJobs();
                //getRunners();
                //store.dispatch('jobs/getJobs');
            }

            async function cleardata() {
                const loader = loading.show();

                const selectedJobIds = ref<number[]>([]);
                allJobs.value.forEach((job) => {
                    if (job.isSelected) {
                        selectedJobIds.value.push(job.id);
                    }
                });
                await store.dispatch(
                    'jobs/deleteSelectedJob',
                    selectedJobIds.value.join('%2C')
                );
                loader.hide();
                loadJobs();
                getRunners();
            }

            function getStatusBackgroundColor(status: ProjectStatus): string {
                switch (status) {
                    case ProjectStatus.InProgress:
                        return '#fd953a';
                    case ProjectStatus.ReadyToOptimize:
                        return '#fed817';
                    case ProjectStatus.ReadyToPurchase:
                        return '#643be0';
                    case ProjectStatus.Purchased:
                        return '#06d6b0';
                    case ProjectStatus.Optimizing:
                        return '#06d6b0';
                    default:
                        return '#fed817';
                }
            }

            const startRUSureDeleteProjectModal = ref(false);

            function existRUSureDeleteProjectModal(state: boolean) {
                startRUSureDeleteProjectModal.value = state;
            }
            function confirmDeleteProject() {
                existRUSureDeleteProjectModal(true);
            }
            function deleteConfirmedProject() {
                cleardata();
                existRUSureDeleteProjectModal(false);
            }

            const isStatusModalOpen = ref(false);

            const jobIdToEditStatus = ref();

            function openStatusModal(id: number) {
                if(store.state.auth.user != null  && store.state.auth.user.isAdmin)
                 {
                    const job = allJobs.value.find((job) => job.id === id);
                    jobIdToEditStatus.value = job?.id;
                    const projectStatus = job?.status;

                    if (projectStatus !== undefined) {
                        checkedStatus.value = getProjectStatusName(projectStatus);
                    } else {
                        checkedStatus.value = 'Unknown'; // Or handle the undefined case as needed
                    }

                    if (projectStatus == ProjectStatus.Purchased)
                    {
                        statusesForModal.value = [
                            {
                            name: 'Ready To Optimize',
                            },
                            {
                            name: 'Ready To Purchase',
                            }
                        ];
                        isStatusModalOpen.value = true;
                    }
                    else if (projectStatus == ProjectStatus.ReadyToPurchase) {
                        statusesForModal.value = [
                            {
                                name: 'Ready To Optimize',
                            },
                            {
                                name: 'Purchased',
                            }
                        ];
                        isStatusModalOpen.value = true;
                    }
                    else
                    {
                        statusesForModal.value = [];
                        isStatusModalOpen.value = false;
                    }
                }
            }

            const changedStatusValue = ref('');


            function changeStatus(status: string) {
                changedStatusValue.value = status;
            }

            const changesStatusNumber = computed(() => {
                if (changedStatusValue.value) {
                    if (changedStatusValue.value === 'In Progress') {
                      return 0;
                    } else if (changedStatusValue.value === 'Ready to Optimize') {
                      return 1;
                    } else if (changedStatusValue.value === 'Optimizing') {
                      return 2;
                    } else if (changedStatusValue.value === 'Ready To Purchase') {
                      return 3;
                    } else if (changedStatusValue.value === 'Purchased'){
                      return 4;
                    } else
                    {
                        return NaN;
                    }
                  } else {
                    return NaN;
                  }
            });

            async function saveNewStatus() {
                if (changedStatusValue.value) {

                    // Find the updated project to pass to the API call
                    const updatedJob = allJobs.value.find(job => job.id === jobIdToEditStatus.value);
                    if (updatedJob && !isNaN(changesStatusNumber.value)) {
                        updatedJob.status = changesStatusNumber.value; 
                        await api.updateJobStatus(updatedJob, store.state.auth.jwt)
                    } else {
                        console.error('Job not found');
                    }
                }
                closeStatusModal();
            }

            function closeStatusModal() {
                isStatusModalOpen.value = false;
            }

            const checkedStatus = ref('');
            const statusesForModal = ref([
                {
                    name: 'In Progress',
                },
                {
                    name: 'Ready To Optimize',
                },
                {
                    name: 'Optimizing',
                },
                {
                    name: 'Ready to Purchase',
                },
                {
                    name: 'Purchased',
                }
            ]);

            const isAddTagsModalOpen = ref(false);
            const tagsModalAddInputExist = ref(false);
            const tagsModalAddInputValue = ref('');
            const tagsModalJobIdValue = ref<number>();
            const startEditTag = ref(false);

            interface Tag {
                id: number;
                name: string;
                userId: number;
            }

            const projectTags = ref<ProjectTag[]>([]);

            function openAddTagsModal(project_tags: ProjectTag[], projectId: number) {
                tagsModalJobIdValue.value = projectId;
                isAddTagsModalOpen.value = true;
                projectTags.value = project_tags;// JSON.parse(JSON.stringify(store.state.tags.items));
            }

            function closeTagsModal() {
                isAddTagsModalOpen.value = false;
                startEditTag.value = false;
                tagsModalAddInputValue.value = '';
                tagsModalJobIdValue.value = 0;
            }

            async function addTag() {
                tagsModalAddInputValue.value = '';
            }

            const startRUSureDeleteTagModal = ref(false);

            function existRUSureDeleteTagModal(state: boolean) {
                startRUSureDeleteTagModal.value = state;
            }
            const projectTagToDelete = ref<ProjectTag>();
            const projectTagToAdd = ref<ProjectTag>();

            function confirmDeleteTag(project_tag: ProjectTag) {
                existRUSureDeleteTagModal(true);
                projectTagToDelete.value = project_tag;
            }
            async function deleteConfirmedTag() {
                await store.dispatch('projectTags/deleteProjectTag', projectTagToDelete.value);
                existRUSureDeleteTagModal(false);
                closeTagsModal();
                loadJobs();
            }

            async function saveTags() {
                //store.state.tags.items = projectTags.value;
                if(tagsModalAddInputValue.value.length > 0)
                {
                    projectTagToAdd.value = {
                        projectId: tagsModalJobIdValue.value ?? 0,
                        tagId: 0,
                        tag: {
                            id: 0,
                            name: tagsModalAddInputValue.value
                        }
                    };
                    await store.dispatch('projectTags/addProjectTag', projectTagToAdd.value);
                    closeTagsModal();
                    loadJobs();
                }
            }
            function cancelAddTags() {
                closeTagsModal();
            }

            async function remove(job: Job) {
                const question = `Do you really wish to delete ${job.projectName}?`;
                if (confirm(question)) {
                    const loader = loading.show();
                    await store.dispatch('jobs/deleteJob', job);
                    loader.hide();
                    reset();
                }
            }

            async function cropping(job: Job)
            {
                const user = await api.profile(store.state.auth.jwt);
                if(user != null && user.credit > 0)
                {
                     await store.dispatch('jobs/croppingJob', job);
                }
                else
                {
                    alert('You don\'t have sufficient credit. Please purchase credit to perform futher process.');
                }
            }

            function update(job: Job) {
                if (job.projectName) {
                    store.dispatch('jobs/updateJob', job);
                } else {
                    reset();
                }
            }

            async function getRunners() {
                runners.value = await api.getActiveJobRunners(store.state.auth.jwt);
            }

            async function resubmit(job: Job, runner: JobRunner) {
                //const loader = loading.show();
                //await api.resubmitProjectToRunner(job.project, runner, store.state.auth.jwt);
                //loader.hide();
                //loadJobs();
            }

            async function download(job: Job) {
                job.urls = await api.downloadJob(job, store.state.auth.jwt);
            }

            async function convert(job: Job, detail: number) {
                //await api.convertProject(
                //    job.project,
                //    detail,
                //    true,
                //    store.state.auth.jwt
                //);
                router.push('/jobs');
            }

            async function showDetails(job: Job) {
                //loader.show({
                //    container: null,
                //    canCancel: false
                //});
                await download(job);
                //loader.hide();
                selectedJob.value = job;

                if (job.status == ProjectStatus.Optimizing || job.status == ProjectStatus.ReadyToOptimize) {
                    modalViewerUrl.value = job.urls?.find(
                        (d) => d.version === 'glb'
                    )?.url;
                }
                else {
                    modalViewerUrl.value = job.urls?.find(
                        (d) => d.version === 'glTF'
                    )?.url;
                }
                showModal.value = true;
                context.emit('selectedJob');
                return true;
            }

            async function showDetails1(job: Job) {
                selectedJob.value = job;
                showModal1.value = true;
                context.emit('selectedJob');
                return true;
            }

            function hideDetails() {
                showModal.value = false;
                context.emit('hideDetails');
            }

            function hideDetails1() {
                showModal1.value = false;
                context.emit('hideDetails');
            }

            function truncatText(name: string): string {
                if (name.length > 18) {
                    return `${name.substring(0, 18)}..`;
                }
                return name;
            }

            function adjustFontSize() {
                const selectElement = document.querySelector('.form-number-input');
                if (selectElement instanceof HTMLElement) {
                    const options = selectElement.querySelectorAll('option');
                    let maxWidth = 0;
                    options.forEach((option) => {
                        if (option.textContent) {
                            maxWidth = Math.max(maxWidth, option.textContent.length);
                        }
                    });
                    const selectWidth = selectElement.clientWidth;
                    const fontSize = Math.min(selectWidth / maxWidth, 15);
                    selectElement.style.fontSize = `${fontSize}px`;
                }
            }

            window.addEventListener('resize', adjustFontSize);
            window.addEventListener('load', adjustFontSize);

            onMounted(() => {
                const storedLayout = localStorage.getItem('layout_project') as
                    | 'table'
                    | 'boxes';
                if (storedLayout) {
                    layout.value = storedLayout;
                }

                const storedProjectName = localStorage.getItem('isProjectNameVisible');
                if (storedProjectName !== null) {
                    isProjectNameVisible.value = JSON.parse(storedProjectName);
                }

                const storedUserName = localStorage.getItem('isUserVisible');
                if (storedUserName !== null) {
                    isUserVisible.value = JSON.parse(storedUserName);
                }

                const storedProjectType = localStorage.getItem('isTypeVisible');
                if (storedProjectType !== null) {
                    isTypeVisible.value = JSON.parse(storedProjectType);
                }
                const storedStatus = localStorage.getItem('isStatusVisible');
                if (storedStatus !== null) {
                    isStatusVisible.value = JSON.parse(storedStatus);
                }

                const storedHeight = localStorage.getItem('isHeightVisible');
                if (storedHeight !== null) {
                    isHeightVisible.value = JSON.parse(storedHeight);
                }

                const storeQuality = localStorage.getItem('isQualityVisible');
                if (storeQuality !== null) {
                    isQualityVisible.value = JSON.parse(storeQuality);
                }
                const storeProcessingDuration = localStorage.getItem('isProcessingDurationVisible');
                if (storeProcessingDuration !== null) {
                    isProcessingDurationVisible.value = JSON.parse(storeProcessingDuration);
                }
                const storeTag = localStorage.getItem('isTagVisible');
                if (storeTag !== null) {
                    isTagVisible.value = JSON.parse(storeTag);
                }

                const storedValueofTable = localStorage.getItem('currentValueofTable');
                if (storedValueofTable !== null) {
                    currentValueofTable.value = parseFloat(storedValueofTable);
                }

                const storedValueofHeader = localStorage.getItem('currentValueofHeader');
                if (storedValueofHeader !== null) {
                    currentValueofHeader.value = parseFloat(storedValueofHeader);
                }
            });

            const deleteProjectsText = computed(() => {
                return `delete${selectedJobsNum.value === allJobs.value.length ? ' all' : ''}`;
            });

            return {
                showBrowsBar,
                updateFontSize,
                isQualityVisible,
                toggleQuality,
                hideQuality,
                showQuality,
                isTagVisible,
                toggleTag,
                hideTag,
                showTag,
                toggleHeight,
                isHeightVisible,
                hideHeight,
                showHeight,
                toggleStatus,
                hideStatus,
                isStatusVisible,
                showStatus,
                toggleType,
                hideType,
                isTypeVisible,
                showType,
                toggleUser,
                hideProjectName,
                hideUser,
                isUserVisible,
                showUser,
                toggleProjectName,
                isProjectNameVisible,
                showProjectName,
                currentValueofTable,
                currentValueofHeader,
                list,
                step,
                min,
                max,
                valuesAndLabels,
                hasConfigs,
                isEditViewModalOpen,
                isFilterViewModalOpen,
                openEditViewModal,
                closeEditViewModal,
                openFilterViewModal,
                closeFilterModal,
                isDropdownOpen,
                toggleDropdown,
                closeDropdown,
                allJobs,
                jobsPerPage,
                currentPage,
                totalPages,
                toggleJobsPerPage,
                getProjectStatusName,
                projectStatusNameList,
                quantityList,
                changePage,
                prevPage,
                nextPage,
                displayedJobs,
                project_layout,
                deleteButton: {
                    color: '#ff5959',
                    textDecoration: 'none',
                    gap: '4px',
                    width: '20px',
                },
                //projectStatusBgColor,
                navigateToNewJob,
                checkedStatusindivisual,
                // status: 'Processing',
                getStatusBackgroundColor,
                isStutasModalOpen,
                toggleQualityModal,
                isQualityModalOpen,
                toggleHeightModal,
                isHeightModalOpen,
                toggleFiltersTagModal,
                isTagModalOpen,
                hasIcon: true,
                unselectAllJobs,
                truncatText,
                notExistText: true,
                selectAllJobs,
                selectJobforedit,
                selectJob,
                allJobsCheckBoxState,
                selectAllJobsCheckBoxState,
                layout,
                setLayout,
                selectedJobsNum,
                layoutClass,
                searchkeyword,
                changeSortingOption,
                search,
                searchByUser,
                allJobs_HasMoreJobs,
                store,
                errorMsg,
                reset,
                cleardata,
                remove,
                update,
                download,
                convert,
                projectType,
                detailType,
                projectStatus,
                selectedJob,
                showModal,
                showModal1,
                hideDetails,
                hideDetails1,
                showDetails,
                showDetails1,
                modalViewerUrl,
                captureMode,
                resubmit,
                runners,
                clearsearch,
                filter_userId,
                getButtonClass,
                cropping,
                toggleFiltersStutasModal,
                filter_detail,
                filter_minHeight,
                filter_maxHeight,
                minInputValue,
                maxInputValue,
                filter_tags,
                filter_status,
                ApplyEditViewConfig,
                ResetEditViewConfig,
                currentMainBar,
                apliedFilersBar,
                ApplyFilter,
                ResetFilter,
                aplliedFilters,
                resetSpecificFilter,
                startRUSureDeleteProjectModal,
                existRUSureDeleteProjectModal,
                confirmDeleteProject,
                deleteConfirmedProject,
                isStatusModalOpen,
                jobIdToEditStatus,
                openStatusModal,
                changedStatusValue,
                changesStatusNumber,

                changeStatus,
                saveNewStatus,
                closeStatusModal,
                checkedStatus,
                statusesForModal,
                isAddTagsModalOpen,
                tagsModalAddInputExist,
                tagsModalAddInputValue,
                tagsModalJobIdValue,
                startEditTag,
                projectTags,
                openAddTagsModal,
                closeTagsModal,
                addTag,
                startRUSureDeleteTagModal,
                existRUSureDeleteTagModal,
                projectTagToDelete,
                confirmDeleteTag,
                deleteConfirmedTag,
                saveTags,
                cancelAddTags,
                copyToClipboard,
                deleteProjectsText,
                getProgressPercentage, // Add the new function to the return values
                isProcessingDurationVisible,
                toggleProcessingDuration,
                hideProcessingDuration,
                showProcessingDuration,
            };
        },
    });