/**
 * Enum representing the possible states of a project in the processing pipeline
 * Used to track progress and determine available actions for a project
 */
export enum ProjectStatus {
    /** Project is being processed by the 3D pipeline */
    InProgress = 0,
    
    /** Initial processing is complete and project is ready for optimization/framing */
    ReadyToOptimize = 1,
    
    /** Project is currently being optimized (framing process) */
    Optimizing = 2,
    
    /** Project optimization is complete and it's ready for purchase */
    ReadyToPurchase = 3,
    
    /** Project has been purchased by the user */
    Purchased = 4
}