import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "alert alert-warning",
  role: "alert"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("main", null, [
    _cache[6] || (_cache[6] = _createElementVNode("img", {
      class: "main-vector",
      src: "images/main-Vector.svg"
    }, null, -1)),
    _createElementVNode("h1", null, [
      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "cntrst" }, "Hello", -1)),
      _cache[2] || (_cache[2] = _createTextVNode()),
      _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
      _createTextVNode(" " + _toDisplayString(_ctx.store.state.auth.user.userName.substring(0, 20)), 1)
    ]),
    _createElementVNode("p", null, "Email : " + _toDisplayString(_ctx.store.state.auth.user.email), 1),
    _createElementVNode("p", null, "Credit : " + _toDisplayString(_ctx.store.state.auth.user.credit), 1),
    (_ctx.store.state.auth.user && !_ctx.store.state.auth.user.isActive)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, " Your account is inactive! "))
      : _createCommentVNode("", true),
    _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
    _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
    _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
    _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
    _createVNode(_component_router_link, {
      to: '/profileupdate',
      style: {"text-decoration-line":"none !important"}
    }, {
      default: _withCtx(() => _cache[4] || (_cache[4] = [
        _createElementVNode("button", { class: "get-started btn" }, [
          _createElementVNode("span", null, "Update Profile")
        ], -1)
      ])),
      _: 1
    }),
    _createElementVNode("button", {
      class: "get-started btn",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logout()))
    }, _cache[5] || (_cache[5] = [
      _createElementVNode("span", null, "Logout", -1)
    ])),
    _cache[11] || (_cache[11] = _createStaticVNode("<div class=\"recs\"><div class=\"left col\"><div class=\"rec rec-left-top\"></div><div class=\"rec rec-left-bottom\"></div></div><div class=\"right col\"><div class=\"rec rec-right-top\"></div><div class=\"rec rec-right-btm\"></div></div></div>", 1))
  ]))
}