
/**
 * Root Application Component
 * 
 * This is the main app component that serves as the application shell.
 * It contains the header and router outlet for displaying different views.
 * It also manages global state and theme switching functionality.
 */
import { useStore } from 'vuex';
import { State } from './store';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import MainHeader from './components/MainHeader.vue';

export default defineComponent({
  name: 'App',
  
  components: {
    MainHeader, // Main navigation header component
  },
  
  /**
   * Setup composition API hook for initializing the component
   * @returns {Object} Store and router instances
   */
  setup() {
    const store = useStore<State>();
    const router = useRouter();
    return { store, router };
  },
  
  /* Commented code for external script loading
  mounted(){
      const externalScript = document.createElement('script')
      externalScript.setAttribute('src', '/js/main.js')
      document.head.appendChild(externalScript)
  },
  updated(){
      const externalScript = document.createElement('script')
      externalScript.setAttribute('src', '/js/main.js')
      document.head.appendChild(externalScript)
  },*/
  
  methods: {
    /**
     * Logs out the current user
     * Removes JWT from localStorage, clears user state, and reloads the page
     */
    logout() {
      const router = useRouter();
      localStorage.removeItem('jwt');
      this.store.commit('setUser', null);
      this.store.commit('setJwt', null);
      window.location.reload();
    },
    
    /**
     * Switches to dark theme by removing the light theme CSS
     */
    switchdarktheme() {
      const lightThemeCss = document.querySelector('.light-theme-css');
      lightThemeCss?.removeAttribute('href');
    },
    
    /**
     * Switches to light theme by setting the light theme CSS
     */
    switchlighttheme() {
      const lightThemeCss = document.querySelector('.light-theme-css');
      lightThemeCss?.setAttribute('href', 'css/style-light.css');
    },
    
    /**
     * Toggles the mobile menu when hamburger icon is clicked
     */
    hamburgerClicked() {
      document.body.classList.toggle('hamburger-open');
    },
  },
});
