import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "icon-container" }
const _hoisted_2 = {
  key: 0,
  class: "ready-purchase-icon status-icon",
  viewBox: "0 0 12 12",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_3 = {
  key: 1,
  class: "processing-icon status-icon",
  viewBox: "0 0 12 12",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_4 = {
  key: 2,
  class: "purchased-icon status-icon",
  viewBox: "0 0 12 12",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_5 = {
  key: 3,
  class: "ready-edit-icon status-icon",
  viewBox: "0 0 12 12",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    (_ctx.status === 'Ready to Purchase')
      ? (_openBlock(), _createElementBlock("svg", _hoisted_2, _cache[0] || (_cache[0] = [
          _createStaticVNode("<g clip-path=\"url(#clip0_122_1051)\" data-v-69f0cb2e><path d=\"M4.5 11C4.77614 11 5 10.7761 5 10.5C5 10.2239 4.77614 10 4.5 10C4.22386 10 4 10.2239 4 10.5C4 10.7761 4.22386 11 4.5 11Z\" stroke=\"white\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-69f0cb2e></path><path d=\"M10 11C10.2761 11 10.5 10.7761 10.5 10.5C10.5 10.2239 10.2761 10 10 10C9.72386 10 9.5 10.2239 9.5 10.5C9.5 10.7761 9.72386 11 10 11Z\" stroke=\"white\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-69f0cb2e></path><path d=\"M0.5 0.5H2.5L3.84 7.195C3.88572 7.4252 4.01095 7.63198 4.19377 7.77915C4.37659 7.92632 4.60535 8.0045 4.84 8H9.7C9.93465 8.0045 10.1634 7.92632 10.3462 7.77915C10.529 7.63198 10.6543 7.4252 10.7 7.195L11.5 3H3\" stroke=\"white\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-69f0cb2e></path></g><defs data-v-69f0cb2e><clipPath id=\"clip0_122_1051\" data-v-69f0cb2e><rect width=\"12\" height=\"12\" fill=\"white\" data-v-69f0cb2e></rect></clipPath></defs>", 2)
        ])))
      : (_ctx.status === 'In Progress')
        ? (_openBlock(), _createElementBlock("svg", _hoisted_3, _cache[1] || (_cache[1] = [
            _createStaticVNode("<g clip-path=\"url(#clip0_122_1081)\" data-v-69f0cb2e><path d=\"M6 1V3\" stroke=\"#111111\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-69f0cb2e></path><path d=\"M6 9V11\" stroke=\"#111111\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-69f0cb2e></path><path d=\"M2.46497 2.46497L3.87997 3.87997\" stroke=\"#111111\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-69f0cb2e></path><path d=\"M8.12 8.12L9.535 9.535\" stroke=\"#111111\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-69f0cb2e></path><path d=\"M1 6H3\" stroke=\"#111111\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-69f0cb2e></path><path d=\"M9 6H11\" stroke=\"#111111\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-69f0cb2e></path><path d=\"M2.46497 9.535L3.87997 8.12\" stroke=\"#111111\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-69f0cb2e></path><path d=\"M8.12 3.87997L9.535 2.46497\" stroke=\"#111111\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-69f0cb2e></path></g><defs data-v-69f0cb2e><clipPath id=\"clip0_122_1081\" data-v-69f0cb2e><rect width=\"12\" height=\"12\" fill=\"white\" data-v-69f0cb2e></rect></clipPath></defs>", 2)
          ])))
        : (_ctx.status === 'Purchased')
          ? (_openBlock(), _createElementBlock("svg", _hoisted_4, _cache[2] || (_cache[2] = [
              _createElementVNode("path", {
                d: "M8 10.5V9.5C8 8.96957 7.78929 8.46086 7.41421 8.08579C7.03914 7.71071 6.53043 7.5 6 7.5H2.5C1.96957 7.5 1.46086 7.71071 1.08579 8.08579C0.710714 8.46086 0.5 8.96957 0.5 9.5V10.5",
                stroke: "#111111",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }, null, -1),
              _createElementVNode("path", {
                d: "M4.25 5.5C5.35457 5.5 6.25 4.60457 6.25 3.5C6.25 2.39543 5.35457 1.5 4.25 1.5C3.14543 1.5 2.25 2.39543 2.25 3.5C2.25 4.60457 3.14543 5.5 4.25 5.5Z",
                stroke: "#111111",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }, null, -1),
              _createElementVNode("path", {
                d: "M8.5 5.5L9.5 6.5L11.5 4.5",
                stroke: "#111111",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }, null, -1)
            ])))
          : (_openBlock(), _createElementBlock("svg", _hoisted_5, _cache[3] || (_cache[3] = [
              _createStaticVNode("<g clip-path=\"url(#clip0_122_1095)\" data-v-69f0cb2e><path d=\"M5.5 2H2C1.73478 2 1.48043 2.10536 1.29289 2.29289C1.10536 2.48043 1 2.73478 1 3V10C1 10.2652 1.10536 10.5196 1.29289 10.7071C1.48043 10.8946 1.73478 11 2 11H9C9.26522 11 9.51957 10.8946 9.70711 10.7071C9.89464 10.5196 10 10.2652 10 10V6.5\" stroke=\"#111111\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-69f0cb2e></path><path d=\"M9.25 1.24999C9.44891 1.05108 9.7187 0.939331 10 0.939331C10.2813 0.939331 10.5511 1.05108 10.75 1.24999C10.9489 1.4489 11.0607 1.71869 11.0607 1.99999C11.0607 2.2813 10.9489 2.55108 10.75 2.74999L6 7.49999L4 7.99999L4.5 5.99999L9.25 1.24999Z\" stroke=\"#111111\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-69f0cb2e></path></g><defs data-v-69f0cb2e><clipPath id=\"clip0_122_1095\" data-v-69f0cb2e><rect width=\"12\" height=\"12\" fill=\"white\" data-v-69f0cb2e></rect></clipPath></defs>", 2)
            ])))
  ]))
}