import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, vModelRadio as _vModelRadio, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "create-new-project has-footer-btns" }
const _hoisted_2 = { class: "container projects-users__container" }
const _hoisted_3 = { class: "create-project__flex" }
const _hoisted_4 = {
  key: 0,
  class: "create-project__configs"
}
const _hoisted_5 = {
  class: "errorMessage",
  style: {"color":"red !important"}
}
const _hoisted_6 = {
  class: "errorMessage",
  style: {"color":"red !important"}
}
const _hoisted_7 = { class: "select-project-type" }
const _hoisted_8 = { class: "choices" }
const _hoisted_9 = { class: "choice__container" }
const _hoisted_10 = { class: "choice__btn btn" }
const _hoisted_11 = { class: "choice__container" }
const _hoisted_12 = { class: "choice__btn btn" }
const _hoisted_13 = {
  class: "errorMessage",
  style: {"color":"red !important"}
}
const _hoisted_14 = { class: "enter-height-block bordered-block has-s-icon" }
const _hoisted_15 = { class: "height-input-container" }
const _hoisted_16 = {
  class: "errorMessage",
  style: {"color":"red !important"}
}
const _hoisted_17 = { class: "quality-presets" }
const _hoisted_18 = { class: "projects-users-select__container projects-users__modal-input" }
const _hoisted_19 = {
  key: 0,
  class: "custom-quality-settings"
}
const _hoisted_20 = { class: "form-group" }
const _hoisted_21 = { class: "form-group" }
const _hoisted_22 = { class: "form-group" }
const _hoisted_23 = { class: "checkbox-group" }
const _hoisted_24 = { class: "custom-checkbox-label" }
const _hoisted_25 = { class: "custom-checkbox-label" }
const _hoisted_26 = { class: "custom-checkbox-label" }
const _hoisted_27 = { class: "custom-checkbox-label" }
const _hoisted_28 = { class: "custom-checkbox-label" }
const _hoisted_29 = { class: "custom-checkbox-label" }
const _hoisted_30 = {
  key: 0,
  class: "selection-info"
}
const _hoisted_31 = { class: "form-group" }
const _hoisted_32 = { class: "form-group" }
const _hoisted_33 = {
  class: "errorMessage",
  style: {"color":"red !important"}
}
const _hoisted_34 = { class: "Normal-high__select-container projects-users-select__container projects-users__modal-input" }
const _hoisted_35 = {
  class: "errorMessage",
  style: {"color":"red !important"}
}
const _hoisted_36 = { class: "toggle-boxes" }
const _hoisted_37 = {
  class: "toggle-box",
  style: {"display":"none"}
}
const _hoisted_38 = { class: "toggle-btn btn" }
const _hoisted_39 = { class: "toggle-box" }
const _hoisted_40 = { class: "toggle-btn btn" }
const _hoisted_41 = {
  class: "errorMessage",
  style: {"color":"red !important"}
}
const _hoisted_42 = {
  key: 1,
  class: "create-project__configs uploid"
}
const _hoisted_43 = {
  key: 0,
  class: "uploid__area improved-upload-area"
}
const _hoisted_44 = {
  key: 1,
  class: "file-details-container"
}
const _hoisted_45 = {
  key: 0,
  class: "selected-file-info"
}
const _hoisted_46 = { class: "file-info" }
const _hoisted_47 = { class: "file-name" }
const _hoisted_48 = { class: "file-size" }
const _hoisted_49 = { class: "file-actions" }
const _hoisted_50 = {
  key: 1,
  class: "upload-progress-container"
}
const _hoisted_51 = { class: "progress-header" }
const _hoisted_52 = { class: "file-name" }
const _hoisted_53 = { class: "file-details" }
const _hoisted_54 = { class: "progress-wrapper" }
const _hoisted_55 = { class: "progress-bar-container" }
const _hoisted_56 = { class: "progress-percentage" }
const _hoisted_57 = { class: "upload-status-container" }
const _hoisted_58 = {
  key: 0,
  class: "upload-status"
}
const _hoisted_59 = {
  key: 1,
  class: "upload-status"
}
const _hoisted_60 = { class: "estimated-time" }
const _hoisted_61 = {
  key: 2,
  class: "uploid"
}
const _hoisted_62 = {
  key: 3,
  class: "modal create-project-modal",
  "data-modal": "create-project-modal"
}
const _hoisted_63 = { class: "modal-content" }
const _hoisted_64 = { class: "create-project-modal-slider swiper" }
const _hoisted_65 = { class: "swiper-control" }
const _hoisted_66 = { class: "footer-btns btns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OpenArrow = _resolveComponent("OpenArrow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[71] || (_cache[71] = _createElementVNode("div", { class: "projects-users__heading" }, [
        _createElementVNode("h2", null, "Create new project")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.basicdetail)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.errorMsg), 1),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
                type: "text",
                class: "top-btm-bordered name-input full-width",
                id: "name",
                name: "name",
                placeholder: "Enter project name"
              }, null, 512), [
                [_vModelText, _ctx.name]
              ]),
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.nameError), 1),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("label", _hoisted_9, [
                    _withDirectives(_createElementVNode("input", {
                      class: "choice__input",
                      type: "radio",
                      name: "choice",
                      id: "choice-viewer",
                      value: '1',
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.projecttype) = $event))
                    }, null, 512), [
                      [_vModelRadio, _ctx.projecttype]
                    ]),
                    _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.projectType[1]), 1)
                  ]),
                  _createElementVNode("label", _hoisted_11, [
                    _withDirectives(_createElementVNode("input", {
                      class: "choice__input",
                      type: "radio",
                      name: "choice",
                      id: "choice-editor",
                      value: '0',
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.projecttype) = $event))
                    }, null, 512), [
                      [_vModelRadio, _ctx.projecttype]
                    ]),
                    _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.projectType[0]), 1)
                  ])
                ])
              ]),
              _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.projecttypeError), 1),
              _createElementVNode("div", _hoisted_14, [
                _cache[27] || (_cache[27] = _createStaticVNode("<svg class=\"icon\" viewBox=\"0 0 40 36\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path opacity=\"0.5\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M28.1309 35.2053C28.1309 35.0192 28.1309 12.8335 28.1309 12.8335L38.7887 6.11133V28.5041C38.7887 28.5041 28.1309 35.3915 28.1309 35.2053Z\" fill=\"#7651E3\"></path><path d=\"M1.67884 7.36854L11.8822 0.857854L38.281 6.23855L28.1182 12.749L1.67884 7.36854Z\" stroke=\"white\" stroke-width=\"0.51779\"></path><path d=\"M1.68086 29.7618L11.921 23.2511L38.3251 28.6323L28.2153 35.1423L1.68086 29.7618Z\" stroke=\"white\" stroke-width=\"0.51779\"></path><path d=\"M1.21212 29.8876V7.49487\" stroke=\"white\" stroke-width=\"0.51779\"></path><path d=\"M28.1987 35.2093L28.1303 12.8347\" stroke=\"white\" stroke-width=\"0.51779\"></path><path d=\"M12.007 22.9762V0.825928\" stroke=\"white\" stroke-width=\"0.51779\"></path><path d=\"M38.7885 28.5053V6.11255\" stroke=\"white\" stroke-width=\"0.51779\"></path></svg>", 1)),
                _createElementVNode("div", _hoisted_15, [
                  _withDirectives(_createElementVNode("input", {
                    class: "right-itm top-btm-bordered",
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.projectheight) = $event)),
                    type: "number",
                    onKeypress: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.numbersOnly && _ctx.numbersOnly(...args))),
                    id: "projectheight",
                    name: "projectheight",
                    placeholder: "Enter project height"
                  }, null, 544), [
                    [_vModelText, _ctx.projectheight]
                  ]),
                  _cache[26] || (_cache[26] = _createElementVNode("span", { class: "height-unit" }, "cm", -1))
                ]),
                _cache[28] || (_cache[28] = _createElementVNode("div", { class: "height-constraint" }, "Max: 300cm", -1))
              ]),
              _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.projectheightError), 1),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _withDirectives(_createElementVNode("select", {
                    class: "projects-users-select",
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.detail) = $event)),
                    name: "detail"
                  }, _cache[29] || (_cache[29] = [
                    _createStaticVNode("<option value=\"0\">Preview Quality</option><option value=\"1\">Reduced Quality</option><option value=\"2\">Standard Quality</option><option value=\"3\">Full Quality</option><option value=\"4\">Raw Quality</option><option value=\"5\">Custom Quality</option>", 6)
                  ]), 512), [
                    [_vModelSelect, _ctx.detail]
                  ]),
                  _createVNode(_component_OpenArrow, { class: "projects-users-select__open-arrow" }),
                  _cache[30] || (_cache[30] = _createStaticVNode("<div class=\"tooltip-container right-aligned\" style=\"position:absolute;right:-30px;top:50%;transform:translateY(-50%);\"><div class=\"tooltip-trigger\">?</div><div class=\"tooltip-content\"><h4>Quality Levels:</h4><div class=\"tooltip-row\"><span class=\"tooltip-label\">Preview:</span><span class=\"tooltip-desc\">Fast, low-quality object for quick previews (lowest quality)</span></div><div class=\"tooltip-row\"><span class=\"tooltip-label\">Reduced:</span><span class=\"tooltip-desc\">Mobile-quality object with 2K texture</span></div><div class=\"tooltip-row\"><span class=\"tooltip-label\">Standard:</span><span class=\"tooltip-desc\">Moderate resource object with 4K texture</span></div><div class=\"tooltip-row\"><span class=\"tooltip-label\">Full:</span><span class=\"tooltip-desc\">High-quality object with 8K texture</span></div><div class=\"tooltip-row\"><span class=\"tooltip-label\">Raw:</span><span class=\"tooltip-desc\">Highest resolution object with multiple 8K textures</span></div><div class=\"tooltip-row\"><span class=\"tooltip-label\">Custom:</span><span class=\"tooltip-desc\">Define your own poly count, texture dimensions, maps, and more</span></div></div></div>", 1))
                ]),
                (_ctx.detail === '5')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                      _cache[52] || (_cache[52] = _createElementVNode("h4", { class: "settings-heading" }, "Custom Quality Settings", -1)),
                      _createElementVNode("div", _hoisted_20, [
                        _cache[31] || (_cache[31] = _createElementVNode("label", { for: "polyCount" }, "Poly Count", -1)),
                        _withDirectives(_createElementVNode("input", {
                          type: "number",
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.customQualitySettings.polyCount) = $event)),
                          id: "polyCount",
                          class: "custom-input",
                          placeholder: "50000",
                          min: "1000",
                          max: "5000000"
                        }, null, 512), [
                          [_vModelText, _ctx.customQualitySettings.polyCount]
                        ]),
                        _cache[32] || (_cache[32] = _createElementVNode("div", { class: "input-hint" }, "Recommended: 50,000 - 1,000,000", -1))
                      ]),
                      _createElementVNode("div", _hoisted_21, [
                        _cache[34] || (_cache[34] = _createElementVNode("label", { for: "textureDimensions" }, "Texture Dimensions", -1)),
                        _withDirectives(_createElementVNode("select", {
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.customQualitySettings.textureDimensions) = $event)),
                          id: "textureDimensions",
                          class: "custom-select"
                        }, _cache[33] || (_cache[33] = [
                          _createStaticVNode("<option value=\"0\">1K</option><option value=\"1\">2K</option><option value=\"2\">4K</option><option value=\"3\">8K</option><option value=\"4\">16K</option>", 5)
                        ]), 512), [
                          [_vModelSelect, _ctx.customQualitySettings.textureDimensions]
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_22, [
                        _cache[47] || (_cache[47] = _createElementVNode("label", null, "Output Texture Maps", -1)),
                        _createElementVNode("div", _hoisted_23, [
                          _createElementVNode("label", _hoisted_24, [
                            _withDirectives(_createElementVNode("input", {
                              type: "checkbox",
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.customQualitySettings.textureMaps.diffuseColor) = $event)),
                              class: "custom-checkbox"
                            }, null, 512), [
                              [_vModelCheckbox, _ctx.customQualitySettings.textureMaps.diffuseColor]
                            ]),
                            _cache[35] || (_cache[35] = _createElementVNode("span", { class: "custom-checkbox-icon" }, null, -1)),
                            _cache[36] || (_cache[36] = _createElementVNode("span", { class: "custom-checkbox-text" }, "Diffuse Color", -1))
                          ]),
                          _createElementVNode("label", _hoisted_25, [
                            _withDirectives(_createElementVNode("input", {
                              type: "checkbox",
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.customQualitySettings.textureMaps.normal) = $event)),
                              class: "custom-checkbox"
                            }, null, 512), [
                              [_vModelCheckbox, _ctx.customQualitySettings.textureMaps.normal]
                            ]),
                            _cache[37] || (_cache[37] = _createElementVNode("span", { class: "custom-checkbox-icon" }, null, -1)),
                            _cache[38] || (_cache[38] = _createElementVNode("span", { class: "custom-checkbox-text" }, "Normal", -1))
                          ]),
                          _createElementVNode("label", _hoisted_26, [
                            _withDirectives(_createElementVNode("input", {
                              type: "checkbox",
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.customQualitySettings.textureMaps.roughness) = $event)),
                              class: "custom-checkbox"
                            }, null, 512), [
                              [_vModelCheckbox, _ctx.customQualitySettings.textureMaps.roughness]
                            ]),
                            _cache[39] || (_cache[39] = _createElementVNode("span", { class: "custom-checkbox-icon" }, null, -1)),
                            _cache[40] || (_cache[40] = _createElementVNode("span", { class: "custom-checkbox-text" }, "Roughness", -1))
                          ]),
                          _createElementVNode("label", _hoisted_27, [
                            _withDirectives(_createElementVNode("input", {
                              type: "checkbox",
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.customQualitySettings.textureMaps.displacement) = $event)),
                              class: "custom-checkbox"
                            }, null, 512), [
                              [_vModelCheckbox, _ctx.customQualitySettings.textureMaps.displacement]
                            ]),
                            _cache[41] || (_cache[41] = _createElementVNode("span", { class: "custom-checkbox-icon" }, null, -1)),
                            _cache[42] || (_cache[42] = _createElementVNode("span", { class: "custom-checkbox-text" }, "Displacement", -1))
                          ]),
                          _createElementVNode("label", _hoisted_28, [
                            _withDirectives(_createElementVNode("input", {
                              type: "checkbox",
                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.customQualitySettings.textureMaps.ambientOcclusion) = $event)),
                              class: "custom-checkbox"
                            }, null, 512), [
                              [_vModelCheckbox, _ctx.customQualitySettings.textureMaps.ambientOcclusion]
                            ]),
                            _cache[43] || (_cache[43] = _createElementVNode("span", { class: "custom-checkbox-icon" }, null, -1)),
                            _cache[44] || (_cache[44] = _createElementVNode("span", { class: "custom-checkbox-text" }, "Ambient Occlusion", -1))
                          ]),
                          _createElementVNode("label", _hoisted_29, [
                            _withDirectives(_createElementVNode("input", {
                              type: "checkbox",
                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.customQualitySettings.textureMaps.all) = $event)),
                              class: "custom-checkbox",
                              onChange: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.toggleAllTextureMaps && _ctx.toggleAllTextureMaps(...args)))
                            }, null, 544), [
                              [_vModelCheckbox, _ctx.customQualitySettings.textureMaps.all]
                            ]),
                            _cache[45] || (_cache[45] = _createElementVNode("span", { class: "custom-checkbox-icon" }, null, -1)),
                            _cache[46] || (_cache[46] = _createElementVNode("span", { class: "custom-checkbox-text" }, "All Maps", -1))
                          ])
                        ]),
                        (_ctx.hasAnyTextureMapSelected)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_30, " Selected: " + _toDisplayString(_ctx.selectedTextureMapsText), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_31, [
                        _cache[49] || (_cache[49] = _createElementVNode("label", { for: "meshType" }, "Mesh Type", -1)),
                        _withDirectives(_createElementVNode("select", {
                          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.customQualitySettings.meshType) = $event)),
                          id: "meshType",
                          class: "custom-select"
                        }, _cache[48] || (_cache[48] = [
                          _createElementVNode("option", { value: "0" }, "Triangles", -1),
                          _createElementVNode("option", { value: "1" }, "Quads", -1)
                        ]), 512), [
                          [_vModelSelect, _ctx.customQualitySettings.meshType]
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_32, [
                        _cache[51] || (_cache[51] = _createElementVNode("label", { for: "textureFormat" }, "Texture Format", -1)),
                        _withDirectives(_createElementVNode("select", {
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.customQualitySettings.textureFormat) = $event)),
                          id: "textureFormat",
                          class: "custom-select"
                        }, _cache[50] || (_cache[50] = [
                          _createElementVNode("option", { value: "0" }, "JPEG", -1),
                          _createElementVNode("option", { value: "1" }, "PNG", -1)
                        ]), 512), [
                          [_vModelSelect, _ctx.customQualitySettings.textureFormat]
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("span", _hoisted_33, _toDisplayString(_ctx.detailError), 1),
                _createElementVNode("div", _hoisted_34, [
                  _withDirectives(_createElementVNode("select", {
                    class: "projects-users-select",
                    "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.featureSensitivity) = $event)),
                    name: "featureSensitivity"
                  }, _cache[53] || (_cache[53] = [
                    _createElementVNode("option", {
                      value: "",
                      disabled: "",
                      hidden: ""
                    }, " Select feature sensitivity ", -1),
                    _createElementVNode("option", {
                      value: "0",
                      selected: ""
                    }, "Normal", -1),
                    _createElementVNode("option", { value: "1" }, "High", -1)
                  ]), 512), [
                    [_vModelSelect, _ctx.featureSensitivity]
                  ]),
                  _createVNode(_component_OpenArrow, { class: "projects-users-select__open-arrow" }),
                  _cache[54] || (_cache[54] = _createStaticVNode("<div class=\"tooltip-container right-aligned\" style=\"position:absolute;right:-30px;top:50%;transform:translateY(-50%);\"><div class=\"tooltip-trigger\">?</div><div class=\"tooltip-content\"><h4>Feature Sensitivity:</h4><div class=\"tooltip-row\"><span class=\"tooltip-label\">High:</span><span class=\"tooltip-desc\">The session uses a slower, more sensitive algorithm to detect landmarks</span></div><div class=\"tooltip-row\"><span class=\"tooltip-label\">Normal:</span><span class=\"tooltip-desc\">Standard landmark detection algorithm</span></div></div></div>", 1))
                ]),
                _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.featureSensitivityError), 1),
                _createElementVNode("div", _hoisted_36, [
                  _createElementVNode("div", _hoisted_37, [
                    _createElementVNode("label", _hoisted_38, [
                      _withDirectives(_createElementVNode("input", {
                        type: "checkbox",
                        "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.useObjectMask) = $event)),
                        checked: ""
                      }, null, 512), [
                        [_vModelCheckbox, _ctx.useObjectMask]
                      ]),
                      _cache[55] || (_cache[55] = _createElementVNode("svg", {
                        class: "toggle-btn-icon",
                        viewBox: "0 0 54 29",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }, [
                        _createElementVNode("rect", {
                          y: "0.181885",
                          width: "54",
                          height: "28",
                          rx: "14",
                          fill: "#2e244d"
                        }),
                        _createElementVNode("circle", {
                          cx: "14",
                          cy: "14.1819",
                          r: "11",
                          fill: "#ffffff17"
                        })
                      ], -1))
                    ]),
                    _cache[56] || (_cache[56] = _createElementVNode("span", null, "Expert Settings", -1))
                  ]),
                  _createElementVNode("div", _hoisted_39, [
                    _createElementVNode("label", _hoisted_40, [
                      _withDirectives(_createElementVNode("input", {
                        type: "checkbox",
                        "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.useObjectMask) = $event)),
                        checked: ""
                      }, null, 512), [
                        [_vModelCheckbox, _ctx.useObjectMask]
                      ]),
                      _cache[57] || (_cache[57] = _createElementVNode("svg", {
                        class: "toggle-btn-icon",
                        viewBox: "0 0 54 29",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }, [
                        _createElementVNode("rect", {
                          y: "0.181885",
                          width: "54",
                          height: "28",
                          rx: "14",
                          fill: "#2e244d"
                        }),
                        _createElementVNode("circle", {
                          cx: "14",
                          cy: "14.1819",
                          r: "11",
                          fill: "#ffffff17"
                        })
                      ], -1))
                    ]),
                    _cache[58] || (_cache[58] = _createStaticVNode("<span>Masking</span><div class=\"tooltip-container small left-aligned\" style=\"display:inline-block;vertical-align:middle;margin-left:10px;\"><div class=\"tooltip-trigger\">?</div><div class=\"tooltip-content\" style=\"right:auto;left:0;\"><h4>Masking Options:</h4><div class=\"tooltip-row\"><span class=\"tooltip-label\">Yes:</span><span class=\"tooltip-desc\">Separate object from the background</span></div><div class=\"tooltip-row\"><span class=\"tooltip-label\">No:</span><span class=\"tooltip-desc\">Keep the environment</span></div></div></div>", 2))
                  ])
                ]),
                _createElementVNode("span", _hoisted_41, _toDisplayString(_ctx.useObjectMaskError), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.uploaddetail)
          ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
              _cache[64] || (_cache[64] = _createElementVNode("div", { class: "upload-header" }, [
                _createElementVNode("h3", null, "Upload your artwork files"),
                _createElementVNode("p", null, "Please upload a zip file containing multiple photos of your artwork")
              ], -1)),
              (!_ctx.selectedFile)
                ? (_openBlock(), _createElementBlock("label", _hoisted_43, [
                    _cache[59] || (_cache[59] = _createElementVNode("div", { class: "upload-icon" }, [
                      _createElementVNode("svg", {
                        width: "60",
                        height: "60",
                        viewBox: "0 0 24 24",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }, [
                        _createElementVNode("path", {
                          d: "M12 4V16M12 4L8 8M12 4L16 8",
                          stroke: "currentColor",
                          "stroke-width": "2",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }),
                        _createElementVNode("path", {
                          d: "M20 16V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V16",
                          stroke: "currentColor",
                          "stroke-width": "2",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        })
                      ])
                    ], -1)),
                    _createElementVNode("input", {
                      type: "file",
                      class: "uploid__input",
                      name: "uploid__input",
                      onChange: _cache[20] || (_cache[20] = 
//@ts-ignore
(...args) => (_ctx.handleFileSelect && _ctx.handleFileSelect(...args))),
                      accept: ".zip"
                    }, null, 32),
                    _cache[60] || (_cache[60] = _createElementVNode("div", { class: "upload-text" }, [
                      _createElementVNode("h4", null, "Drag & drop your ZIP file here"),
                      _createElementVNode("p", null, "or click to browse your files"),
                      _createElementVNode("span", { class: "file-requirements" }, "Supported format: ZIP archives only")
                    ], -1))
                  ]))
                : _createCommentVNode("", true),
              (_ctx.selectedFile)
                ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                    (_ctx.uploadProgress < 1)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                          _cache[62] || (_cache[62] = _createStaticVNode("<div class=\"file-preview\"><svg width=\"40\" height=\"40\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M15.7161 16.2234H8.49609\" stroke=\"currentColor\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M15.7161 12.0369H8.49609\" stroke=\"currentColor\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M11.2511 7.86011H8.49609\" stroke=\"currentColor\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15.908 2.75C15.908 2.75 8.23198 2.754 8.21998 2.754C5.45998 2.771 3.75098 4.587 3.75098 7.357V16.553C3.75098 19.337 5.47298 21.16 8.25698 21.16C8.25698 21.16 15.932 21.157 15.945 21.157C18.705 21.14 20.415 19.323 20.415 16.553V7.357C20.415 4.573 18.692 2.75 15.908 2.75Z\" stroke=\"currentColor\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg></div>", 1)),
                          _createElementVNode("div", _hoisted_46, [
                            _createElementVNode("p", _hoisted_47, _toDisplayString(_ctx.selectedFileName), 1),
                            _createElementVNode("p", _hoisted_48, "Size: " + _toDisplayString(_ctx.formatBytes(_ctx.selectedFile.size)), 1)
                          ]),
                          _createElementVNode("div", _hoisted_49, [
                            _createElementVNode("button", {
                              class: "remove-file-btn",
                              onClick: _cache[21] || (_cache[21] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.removeFileSelect && _ctx.removeFileSelect(...args)), ["stop"]))
                            }, _cache[61] || (_cache[61] = [
                              _createElementVNode("svg", {
                                width: "20",
                                height: "20",
                                viewBox: "0 0 24 24",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg"
                              }, [
                                _createElementVNode("path", {
                                  d: "M18 6L6 18M6 6L18 18",
                                  stroke: "#ff5959",
                                  "stroke-width": "2",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round"
                                })
                              ], -1)
                            ])),
                            _createElementVNode("button", {
                              class: "main-btn btn-m btn upload-btn",
                              onClick: _cache[22] || (_cache[22] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.uploadFile && _ctx.uploadFile(...args)), ["stop"]))
                            }, "Upload")
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.uploadProgress > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
                          _createElementVNode("div", _hoisted_51, [
                            _createElementVNode("span", _hoisted_52, _toDisplayString(_ctx.selectedFileName), 1),
                            _createElementVNode("span", _hoisted_53, "Uploaded: " + _toDisplayString(_ctx.formatBytes(_ctx.uploadedSize)) + " of " + _toDisplayString(_ctx.formatBytes(_ctx.selectedFile.size)), 1)
                          ]),
                          _createElementVNode("div", _hoisted_54, [
                            _createElementVNode("div", _hoisted_55, [
                              _createElementVNode("div", {
                                class: "progress-bar-fill",
                                style: _normalizeStyle({width: _ctx.smoothProgress + '%'})
                              }, null, 4)
                            ]),
                            _createElementVNode("span", _hoisted_56, _toDisplayString(Math.round(_ctx.smoothProgress)) + "%", 1)
                          ]),
                          _createElementVNode("div", _hoisted_57, [
                            (_ctx.uploadProgress === 100)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_58, "Upload complete! Redirecting to projects..."))
                              : (_ctx.uploadProgress > 0 && _ctx.uploadProgress < 100)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_59, [
                                    _cache[63] || (_cache[63] = _createTextVNode(" Uploading your file... ")),
                                    _createElementVNode("span", _hoisted_60, _toDisplayString(_ctx.estimatedTimeRemaining), 1)
                                  ]))
                                : _createCommentVNode("", true)
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (!_ctx.uploaddetail)
          ? (_openBlock(), _createElementBlock("div", _hoisted_61, _cache[65] || (_cache[65] = [
              _createStaticVNode("<div class=\"compact-layout\"><label class=\"uploid__area preview-container\"><div class=\"preview-content\"><img src=\"/images/capture.png\" alt=\"3D Capture Process\" class=\"preview-image\"><div class=\"preview-overlay\"><div class=\"preview-text\"><h3>Capture Process Visualization</h3><p>Our system will create a detailed 3D model from multiple angles</p></div></div></div></label><div class=\"upload-instructions\"><h4>Tips for best results:</h4><ul><li>Take photos in good lighting conditions (natural light works best)</li><li>Capture multiple angles (15-20 photos recommended)</li><li>Ensure photos are taken at consistent distance from the object</li><li>Include close-up details for higher quality results</li><li>Maintain consistent lighting across all photos</li><li>Avoid reflective surfaces when possible</li><li>Use a neutral background for better object isolation</li><li>Ensure all parts of the artwork are visible in multiple photos</li></ul></div></div>", 1)
            ])))
          : _createCommentVNode("", true),
        _cache[70] || (_cache[70] = _createElementVNode("div", { id: "#progress-bar-container" }, null, -1)),
        (_ctx.showModal)
          ? (_openBlock(), _createElementBlock("div", _hoisted_62, [
              _createElementVNode("div", _hoisted_63, [
                _createElementVNode("div", _hoisted_64, [
                  _cache[68] || (_cache[68] = _createStaticVNode("<div class=\"swiper-wrapper\"><div class=\"swiper-slide entire\"><div class=\"choces-heading\"><button class=\"second-btn btn-xs btn\">Step1</button><span class=\"main-itm main-itm-ltr-space\">Take a picture of the entire artwork</span></div><div class=\"slide-choces\"><div class=\"slide-choce plazma\"><span class=\"main-itm main-itm-ltr-space\">Painting</span></div><div class=\"slide-choce box\"><span class=\"main-itm main-itm-ltr-space\">Sculpture / Object</span></div></div><svg class=\"close close-modal\" viewBox=\"0 0 20 20\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path class=\"close-modal\" d=\"M14.1422 15.5563L9.89954 11.3137L5.6569 15.5563L4.24268 14.1421L8.48532 9.89949L4.24268 5.65685L5.6569 4.24264L9.89954 8.48528L14.1422 4.24264L15.5564 5.65685L11.3137 9.89949L15.5564 14.1421L14.1422 15.5563Z\" fill=\"white\"></path></svg></div><div class=\"swiper-slide angles\"><div class=\"choces-heading\"><button class=\"second-btn btn-xs btn\">Step2</button><span class=\"main-itm main-itm-ltr-space\">Take a picture of the entire artwork</span></div><div class=\"slide-choces\"><div class=\"slide-choce plazma\"><span class=\"main-itm main-itm-ltr-space\">Painting</span></div><div class=\"slide-choce box\"><span class=\"main-itm main-itm-ltr-space\">Sculpture / Object</span></div></div><svg class=\"close close-modal\" viewBox=\"0 0 20 20\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path class=\"close-modal\" d=\"M14.1422 15.5563L9.89954 11.3137L5.6569 15.5563L4.24268 14.1421L8.48532 9.89949L4.24268 5.65685L5.6569 4.24264L9.89954 8.48528L14.1422 4.24264L15.5564 5.65685L11.3137 9.89949L15.5564 14.1421L14.1422 15.5563Z\" fill=\"white\"></path></svg></div></div>", 1)),
                  _createElementVNode("div", _hoisted_65, [
                    _cache[66] || (_cache[66] = _createElementVNode("div", { class: "swiper-button-prev" }, null, -1)),
                    _createElementVNode("button", {
                      class: "got-it-btn second-btn btn-l btn close-modal",
                      onClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.hideDetails()))
                    }, " Got it "),
                    _cache[67] || (_cache[67] = _createElementVNode("div", { class: "swiper-button-next" }, null, -1))
                  ]),
                  _cache[69] || (_cache[69] = _createElementVNode("div", { class: "swiper-pagination" }, null, -1))
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_66, [
      (_ctx.basicdetail)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "main-btn btn-l btn",
            onClick: _cache[24] || (_cache[24] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
          }, " Next "))
        : _createCommentVNode("", true),
      (_ctx.uploaddetail && _ctx.uploadProgress === 0)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "secondary-btn btn-l btn",
            onClick: _cache[25] || (_cache[25] = ($event: any) => {_ctx.basicdetail = true; _ctx.uploaddetail = false;})
          }, " Back "))
        : _createCommentVNode("", true)
    ])
  ]))
}