import { defineComponent, ref, watchEffect, computed } from 'vue';
    import { useForm, useField } from 'vee-validate';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { State } from '../store';
    import { Project } from '../models/Project';
    import { ProjectUpload } from '../models/ProjectUpload';
    import { ProjectType } from '../models/ProjectType';
    import { FeatureSensitivity } from '../models/FeatureSensitivity';
    import { InitProjectUploadRequestData } from '../models/InitProjectUploadRequestData';
    import { PartETag } from '../models/PartETag';
    import { CaptureMode } from '../models/CaptureMode';
    

    import OpenArrow from '../components/OpenArrow.vue';


    import axios from 'axios';
    const showDashboard = ref(false);



    export default defineComponent({
        components: {
            //Dashboard,
            OpenArrow,
        },
        setup() {
            const simpleSchema = {
                name(value: string): boolean | string {
                    if (value && value.trim()) {
                        return true;
                    }
                    return 'Name is required';
                },
                projecttype(value: string | undefined): boolean | string {
                    if (value && value.trim()) {
                        return true;
                    }
                    return 'Type is required';
                },
                projectheight(value: number): boolean | string {
                    if (!value) {
                        return 'Height is required';
                    }
                    if (value <= 0) {
                        return 'Height must be greater than 0';
                    }
                    if (value > 300) {
                        return 'Height exceeds reasonable limits (max 300cm)';
                    }
                    return true;
                },
                detail(value: number): boolean | string {
                    if (value && value >= 0) {
                        return true;
                    }
                    return 'Detail is required';
                },
                featureSensitivity(value: number): boolean | string {
                    if (value && value >= 0) {
                        return true;
                    }
                    return 'Feature sensitivity is required';
                },
            };

            const { handleSubmit } = useForm({ validationSchema: simpleSchema });
            const { value: name, errorMessage: nameError } = useField('name');
            const { value: projecttype, errorMessage: projecttypeError } =
                useField('projecttype');
            const { value: projectheight, errorMessage: projectheightError } =
                useField('projectheight');
            const { value: detail, errorMessage: detailError } = useField('detail');
            const { value: featureSensitivity, errorMessage: featureSensitivityError } =
                useField('featureSensitivity');
            const { value: useObjectMask, errorMessage: useObjectMaskError } =
                useField('useObjectMask');
            const { value: projectfile, errorMessage: projectfileError } =
                useField('projectfile');
            const store = useStore<State>();
            const errorMsg = ref('');
            const router = useRouter();
            const submitting = ref(false);
            const basicdetail = ref(true);
            const uploaddetail = ref(false);
            const projectType = ProjectType;
            const images = ref<File[]>([]);
            let project: Project;
            let projectUpload: ProjectUpload;
            const showModal = ref(false);


            const selectedFile = ref<File | null>(null);
            const selectedFileName = ref('');
            const partSize = ref(52428800); // 50 MB
            const partETags = ref<PartETag[]>([]);
            const uploadProgress = ref(0);
            const uploadedSize = ref(0);
            const uploadSpeeds = ref<number[]>([]);
            const uploadStartTime = ref<number>(0);
            const estimatedTimeRemaining = ref<string>('');
            const smoothProgress = ref<number>(0);

            const customQualitySettings = ref({
                polyCount: 50000,
                textureDimensions: '3', // Default to 8K
                textureMaps: {
                    diffuseColor: true,
                    normal: true,
                    roughness: false,
                    displacement: false,
                    ambientOcclusion: false,
                    all: false
                },
                meshType: '0', // Default to Triangles
                textureFormat: '1' // Default to PNG
            });

            function formatBytes(bytes: number) {
                if (bytes === 0) return '0 Bytes';
                const k = 1024;
                const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
                const i = Math.floor(Math.log(bytes) / Math.log(k));
                return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
            }

            function handleFileSelect(event: any) {
                const file = event.target.files[0];
                if (file && file.name.endsWith('.zip')) {
                    selectedFile.value = file;
                    selectedFileName.value = file.name;
                } else {
                    alert('Please select a zip file');
                }
            }

            function removeFileSelect() {
                selectedFile.value = null;
                selectedFileName.value = '';
            }

            async function uploadFile() {
                if (!selectedFile.value) {
                    alert('No file selected');
                    return;
                }

                try {
                    // Reset progress tracking
                    uploadProgress.value = 0;
                    smoothProgress.value = 0;
                    uploadedSize.value = 0;
                    uploadSpeeds.value = [];
                    uploadStartTime.value = Date.now();
                    estimatedTimeRemaining.value = 'Calculating...';
                    
                    // Start progress animation
                    const progressAnimation = setInterval(() => {
                        if (smoothProgress.value < uploadProgress.value) {
                            smoothProgress.value += 0.2;
                        }
                    }, 50);

                    const fileName = selectedFile.value.name;
                    const projectId = project.id;
                    const tmp_multipart_upload_filenames: Record<string, number> = {};

                    // Update the filenames and parts count
                    const partCount = getPartCount(selectedFile.value.size, partSize.value);
                    const key = `${projectId}/${fileName}`;
                    tmp_multipart_upload_filenames[key] = partCount;

                    const requestdata = {
                        project_id: projectId,
                        multipart_upload_filenames: tmp_multipart_upload_filenames
                    };

                    // Initialization phase (5%)
                    uploadProgress.value = 2;
                    
                    const response = await store.dispatch('projects/uploadZipInitProcess', requestdata);
                    
                    uploadProgress.value = 5;

                    if (response && response.multipart_upload_presigned_urls) {
                        updateProjectUpload({ projectId: projectId, id: response.project_upload_id, files: [], });

                        type PresignedUrlEntry = { [filename: string]: { [key: string]: string }; };
                        const presignedUrls: PresignedUrlEntry = response.multipart_upload_presigned_urls;

                        // Create a new File object with the updated name
                        const newFileName = `${response.object_id}.zip`;
                        const newFile = new File([selectedFile.value], newFileName, { type: selectedFile.value.type });
                        selectedFile.value = newFile;

                        const filename = `${projectId}/${selectedFile.value.name}`;
                        
                        // Find the presigned URL entry that matches the filename
                        const uploadDetails = presignedUrls[filename];
                        const upload_id = ref('');

                        let totalPartsUploaded = 0;
                        let lastUpdateTime = Date.now();
                        let lastUploadedSize = 0;
                        const totalSize = selectedFile.value.size;
                        const uploadPromises = [];
                        
                        // Calculate upload phase percentages (5-90%)
                        const UPLOAD_PHASE_START = 5;
                        const UPLOAD_PHASE_END = 90;
                        const UPLOAD_PHASE_RANGE = UPLOAD_PHASE_END - UPLOAD_PHASE_START;

                        for (const [partNumberStr, presignedUrl] of Object.entries(uploadDetails)) {
                            if (partNumberStr !== 'upload_id') {
                                try {
                                    const partNumber = parseInt(partNumberStr, 10);

                                    const start = (partNumber - 1) * partSize.value;
                                    const end = Math.min(partNumber * partSize.value, selectedFile.value.size);
                                    const filePart = selectedFile.value.slice(start, end);
                                    
                                    // Create a function that returns a promise
                                    const uploadPromise = axios.put(presignedUrl, filePart, {
                                        headers: {
                                            'Content-Type': 'application/zip',
                                        },
                                        onUploadProgress: (progressEvent) => {
                                            // Update progress for this specific part
                                            const partProgress = progressEvent.loaded / (progressEvent.total || end - start);
                                            const partSize = end - start;
                                            const partContribution = (partSize / totalSize) * partProgress;
                                            
                                            // Calculate overall progress including this part's current progress
                                            const overallProgress = UPLOAD_PHASE_START + 
                                                (uploadedSize.value / totalSize) * UPLOAD_PHASE_RANGE +
                                                partContribution * UPLOAD_PHASE_RANGE;
                                                
                                            uploadProgress.value = Math.min(UPLOAD_PHASE_END, Math.round(overallProgress));
                                            
                                            // Update speed calculation
                                            const now = Date.now();
                                            if (now - lastUpdateTime > 1000) { // Update speed every second
                                                const timeDiff = (now - lastUpdateTime) / 1000; // in seconds
                                                const sizeDiff = uploadedSize.value + progressEvent.loaded - lastUploadedSize;
                                                const speed = sizeDiff / timeDiff; // bytes per second
                                                
                                                // Keep last 5 speed measurements for averaging
                                                uploadSpeeds.value.push(speed);
                                                if (uploadSpeeds.value.length > 5) {
                                                    uploadSpeeds.value.shift();
                                                }
                                                
                                                // Calculate average speed
                                                const avgSpeed = uploadSpeeds.value.reduce((a, b) => a + b, 0) / uploadSpeeds.value.length;
                                                
                                                // Calculate time remaining
                                                const remainingBytes = totalSize - (uploadedSize.value + progressEvent.loaded);
                                                const remainingSeconds = remainingBytes / avgSpeed;
                                                
                                                // Format time remaining
                                                estimatedTimeRemaining.value = formatTimeRemaining(remainingSeconds);
                                                
                                                lastUpdateTime = now;
                                                lastUploadedSize = uploadedSize.value + progressEvent.loaded;
                                            }
                                        }
                                    }).then(uploadResponse => {
                                        partETags.value.push({
                                            PartNumber: partNumber,
                                            ETag: uploadResponse.headers.etag.slice(1, -1)
                                        });

                                        totalPartsUploaded++;
                                        uploadedSize.value += end - start;
                                        
                                        // Calculate precise progress based on actual uploaded bytes
                                        const precise = UPLOAD_PHASE_START + (uploadedSize.value / totalSize) * UPLOAD_PHASE_RANGE;
                                        uploadProgress.value = Math.min(UPLOAD_PHASE_END, Math.round(precise));

                                        return uploadResponse;
                                    }).catch(uploadError => {
                                        console.error(`Error uploading part ${partNumberStr} of file ${filename}:`, uploadError);
                                        throw uploadError; // Rethrow to handle errors in Promise.all
                                    });

                                    // Add promise to the array
                                    uploadPromises.push(uploadPromise);

                                } catch (uploadError) {
                                    console.error(`Error uploading part ${partNumberStr} of file ${filename}:`, uploadError);
                                }
                            }
                            else {
                                upload_id.value = presignedUrl;
                            }
                        }

                        // Use Promise.all to wait for all uploads to complete
                        Promise.all(uploadPromises).then(async (results) => {
                            console.log('All parts uploaded successfully:', results);

                            // Processing phase (90-100%)
                            uploadProgress.value = 90;
                            estimatedTimeRemaining.value = 'Processing...';

                            // Step 3: Notify the server that all parts have been uploaded
                            await store.dispatch('projects/finishAndRun', {
                                filename: filename,
                                upload_id: upload_id.value,
                                parts: partETags.value,
                                projectUploadId: projectUpload.id,
                                projectId: project.id,
                                detail: detail.value,
                                useObjectMask: useObjectMask.value === 'true',
                                featureSensitivity: featureSensitivity.value,
                            });

                            uploadProgress.value = 100; // Set to 100% after finalization
                            smoothProgress.value = 100; // Ensure the smooth progress catches up
                            estimatedTimeRemaining.value = 'Complete';
                            
                            // Stop the progress animation
                            clearInterval(progressAnimation);
                            
                            // Redirect after a brief delay to show 100%
                            setTimeout(() => {
                                router.push('projects');
                            }, 1000);

                        }).catch((error) => {
                            console.error('Error in uploading one or more parts:', error);
                            clearInterval(progressAnimation);
                        });
                    }
                } catch (error) {
                    console.error('Error during file upload:', error);
                    uploadProgress.value = 0;
                    smoothProgress.value = 0;
                }
            }
            
            // Helper function to format time remaining
            function formatTimeRemaining(seconds: number): string {
                if (!isFinite(seconds) || seconds <= 0) {
                    return 'Almost done...';
                }
                
                if (seconds < 60) {
                    return `${Math.round(seconds)} seconds remaining`;
                } else if (seconds < 3600) {
                    return `${Math.round(seconds / 60)} minutes remaining`;
                } else {
                    const hours = Math.floor(seconds / 3600);
                    const minutes = Math.round((seconds % 3600) / 60);
                    return `${hours}h ${minutes}m remaining`;
                }
            }

            useObjectMask.value = 'true';
            featureSensitivity.value = '0';
            projecttype.value = '1';
            detail.value = '2';


            function updateProjectUpload(newValues: Partial<ProjectUpload>) {
                projectUpload = { ...projectUpload, ...newValues, updated: new Date() };
            }

            function toggleAllTextureMaps() {
                if (customQualitySettings.value.textureMaps.all) {
                    // If "All Maps" is checked, check all other maps
                    customQualitySettings.value.textureMaps.diffuseColor = true;
                    customQualitySettings.value.textureMaps.normal = true;
                    customQualitySettings.value.textureMaps.roughness = true;
                    customQualitySettings.value.textureMaps.displacement = true;
                    customQualitySettings.value.textureMaps.ambientOcclusion = true;
                }
            }

            watchEffect(() => {
                const maps = customQualitySettings.value.textureMaps;
                // If all individual maps are checked, also check "All Maps"
                customQualitySettings.value.textureMaps.all = 
                    maps.diffuseColor && 
                    maps.normal && 
                    maps.roughness && 
                    maps.displacement && 
                    maps.ambientOcclusion;
            });

            // Add validation function for custom quality settings
            function validateCustomQuality(): boolean | string {
                if (detail.value !== '5') {
                    return true; // Not using custom quality
                }
                
                // Validate poly count
                const polyCount = parseInt(customQualitySettings.value.polyCount.toString());
                if (isNaN(polyCount) || polyCount < 1000 || polyCount > 5000000) {
                    return 'Poly count must be between 1,000 and 5,000,000';
                }
                
                // Validate texture maps - at least one must be selected
                const maps = customQualitySettings.value.textureMaps;
                if (!maps.diffuseColor && !maps.normal && !maps.roughness && 
                    !maps.displacement && !maps.ambientOcclusion && !maps.all) {
                    return 'At least one texture map must be selected';
                }
                
                return true;
            }

            // Update the onSubmit function to validate custom settings
            const onSubmit = handleSubmit(async () => {
                try {
                    errorMsg.value = '';
                    submitting.value = true;

                    if (basicdetail.value) {
                        let ptype = projectType.Sculpture;
                        if (projecttype.value != '1') {
                            ptype = projectType.Painting;
                        }

                        // Validate custom quality if selected
                        if (detail.value === '5') {
                            const validationResult = validateCustomQuality();
                            if (validationResult !== true) {
                                errorMsg.value = validationResult as string;
                                submitting.value = false;
                                return;
                            }
                        }

                        // Create project submission data
                        const projectData = {
                            name: name.value,
                            objectMask: true,
                            type: ptype,
                            height: projectheight.value,
                            captureMode: CaptureMode.ImportImages
                        };

                        // Add custom quality specifications if selected
                        if (detail.value === '5') {
                            // Convert texture maps to the format expected by the API
                            // These values must match OutputTextureMapEnum in the C# model:
                            // 0 = All
                            // 1 = DiffuseColor
                            // 2 = Normal
                            // 3 = Roughness
                            // 4 = Displacement
                            // 5 = AmbientOcclusion
                            const outputTextureMaps = [];
                            const maps = customQualitySettings.value.textureMaps;
                            
                            if (maps.all || (maps.diffuseColor && maps.normal && maps.roughness && maps.displacement && maps.ambientOcclusion)) {
                                outputTextureMaps.push(0); // All maps
                            } else {
                                if (maps.diffuseColor) outputTextureMaps.push(1);
                                if (maps.normal) outputTextureMaps.push(2);
                                if (maps.roughness) outputTextureMaps.push(3);
                                if (maps.displacement) outputTextureMaps.push(4);
                                if (maps.ambientOcclusion) outputTextureMaps.push(5);
                            }

                            // Create custom configuration
                            // These properties match the C# model's JobCustomDetailSpecifications class
                            // TextureDimensions enum: 0=1K, 1=2K, 2=4K, 3=8K, 4=16K
                            // TextureFormat enum: 0=JPG, 1=PNG
                            // MeshPrimitive enum: 0=Triangle, 1=Quad
                            const customConfig = {
                                MaxPolyCount: parseInt(customQualitySettings.value.polyCount.toString()),
                                TextureDimensions: parseInt(customQualitySettings.value.textureDimensions),
                                OutputTextureMaps: outputTextureMaps,
                                TextureFormat: parseInt(customQualitySettings.value.textureFormat),
                                MeshPrimitive: parseInt(customQualitySettings.value.meshType)
                            };

                            // Add this to the project data
                            (projectData as any).JobCustomDetailSpecifications = [customConfig];
                        }

                        project = await store.dispatch('projects/addProject', projectData);

                        if (project != null) {
                            basicdetail.value = false;
                            uploaddetail.value = true;
                        }
                    }
                    uploaddetail.value = true;
                    submitting.value = false;
                } catch (reason) {
                    submitting.value = false;
                    errorMsg.value =
                        'Please enter valid details. Project name should be unique.';
                }
            });


            // Function to count the number of parts required
            function getPartCount(fileSize: number, tmppartSize: number): number {
                return Math.ceil(fileSize / tmppartSize);
            }


            function numbersOnly(evt: any) {
                evt = evt || window.event;
                const charCode = evt.which ? evt.which : evt.keyCode;
                const input = evt.target.value;

                if (
                    (charCode !== 8 &&
                        charCode !== 46 &&
                        (charCode < 48 || charCode > 57)) ||
                    (charCode === 46 && (input.indexOf('.') !== -1 || input === '')) ||
                    (input.indexOf('.') !== -1 &&
                        input.split('.')[1].length >= 1 &&
                        charCode !== 8)
                ) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            }

            function showDetails() {
                showModal.value = true;
            }

            function hideDetails() {
                showModal.value = false;
            }

            // Computed properties for texture maps selection
            const hasAnyTextureMapSelected = computed(() => {
                const maps = customQualitySettings.value.textureMaps;
                return maps.diffuseColor || maps.normal || maps.roughness || 
                       maps.displacement || maps.ambientOcclusion || maps.all;
            });
            
            const selectedTextureMapsText = computed(() => {
                const maps = customQualitySettings.value.textureMaps;
                const selected = [];
                
                if (maps.all) return 'All Maps';
                
                if (maps.diffuseColor) selected.push('Diffuse Color');
                if (maps.normal) selected.push('Normal');
                if (maps.roughness) selected.push('Roughness');
                if (maps.displacement) selected.push('Displacement');
                if (maps.ambientOcclusion) selected.push('Ambient Occlusion');
                
                return selected.join(', ');
            });

            return {
                name,
                nameError,
                projecttype,
                projecttypeError,
                projectheight,
                projectheightError,
                projectfile,
                projectfileError,
                useObjectMask,
                useObjectMaskError,
                detail,
                onSubmit,
                detailError,
                featureSensitivity,
                featureSensitivityError,
                errorMsg,
                submitting,
                basicdetail,
                uploaddetail,
                projectType,
                numbersOnly,
                showDetails,
                hideDetails,
                showModal,
                images,
                isModalOpen: false,
                selectedFile,
                selectedFileName,
                partSize,
                partETags,
                handleFileSelect,
                removeFileSelect,
                uploadFile,
                uploadProgress,
                formatBytes,
                uploadedSize,
                deleteButton: {
                    color: '#ff5959',
                    textDecoration: 'none',
                    gap: '4px',
                    width: '20px',
                },
                smoothProgress,
                estimatedTimeRemaining,
                customQualitySettings,
                toggleAllTextureMaps,
                hasAnyTextureMapSelected,
                selectedTextureMapsText
            };
        },
    });