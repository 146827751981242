import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("main", null, [
    _cache[8] || (_cache[8] = _createElementVNode("h1", { class: "page-title" }, "Edit Profile", -1)),
    _createElementVNode("form", {
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"])),
      class: "edit-form"
    }, [
      _cache[3] || (_cache[3] = _createElementVNode("p", null, "Name:", -1)),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editedUser.userName) = $event)),
        type: "text",
        class: "top-btm-bordered",
        id: "userName",
        name: "userName"
      }, null, 512), [
        [_vModelText, _ctx.editedUser.userName]
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
      _cache[5] || (_cache[5] = _createElementVNode("p", null, "Company Name:", -1)),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editedUser.companyName) = $event)),
        type: "text",
        class: "top-btm-bordered",
        id: "companyName",
        name: "companyName"
      }, null, 512), [
        [_vModelText, _ctx.editedUser.companyName]
      ]),
      _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
      _cache[7] || (_cache[7] = _createElementVNode("button", {
        class: "get-started btn",
        type: "submit"
      }, [
        _createElementVNode("span", null, "Save Changes")
      ], -1))
    ], 32)
  ]))
}