
import { useStore } from 'vuex';
import { State } from '../store';
import { JobRunner } from '../models/JobRunner';
import { defineComponent, ref, computed, reactive, nextTick } from 'vue';
import api from '../api'

export default defineComponent({
    setup() {
        const store = useStore<State>();
        const runners = ref<JobRunner[]>([]);
        const pingInProgress = reactive<Record<number, boolean>>({});
        const editingRunnerId = ref<number | null>(null);
        const editingRunnerName = ref('');
        const nameInput = ref<HTMLInputElement | null>(null);
        const expectedVersion = ref(0);

        // Helper function to format dates consistently
        const formatDate = (date: Date) => {
            return `${date.toLocaleDateString('en-US')}, ${date.toLocaleTimeString('en-US')}`;
        };

        api.getExpectedbRunnerVersion(store.state.auth.jwt).then(version => expectedVersion.value = version);
        api.getJobRunners(store.state.auth.jwt).then(data => runners.value = data)
        function setActivationState(runner: JobRunner) {
            if (runner.isActive) {
                api.activate(store.state.auth.jwt, runner);
            } else {
                api.deactivate(store.state.auth.jwt, runner);
            }
        }

        const layout = ref<'table' | 'boxes'>('table');
        const setLayout = (newLayout: 'table' | 'boxes') => {
            layout.value = newLayout;
        };


        const getButtonClass = (buttonLayout: 'table' | 'boxes') => {
            return layout.value === buttonLayout
                ? 'main-btn projects-users__switch-btn btn'
                : 'second-btn projects-users__switch-btn btn';
        };

        const layoutClass = computed(() => {
            return layout.value === 'table' ? 'user-layout' : 'user-layout layout-boxes';
        });

        function startEditingName(runner: JobRunner) {
            editingRunnerId.value = runner.id;
            editingRunnerName.value = runner.name;
            // Focus the input after the DOM updates
            nextTick(() => {
                const inputElement = document.querySelector('.edit-name-input') as HTMLInputElement;
                if (inputElement) {
                    inputElement.focus();
                }
            });
        }

        async function saveRunnerName(runner: JobRunner) {
            if (editingRunnerName.value.trim() !== '' && editingRunnerName.value !== runner.name) {
                // Create a copy of the runner with the updated name
                const updatedRunner = { ...runner, name: editingRunnerName.value.trim() };
                try {
                    await api.updateRunnerName(store.state.auth.jwt, updatedRunner);
                    // Refresh the runners list to get the updated data
                    runners.value = await api.getJobRunners(store.state.auth.jwt);
                } catch (error) {
                    console.error('Failed to update runner name:', error);
                    alert('Failed to update runner name. Please try again.');
                }
            }
            // Reset the editing state
            editingRunnerId.value = null;
            editingRunnerName.value = '';
        }

        async function remove(runner: JobRunner) {
            if (confirm('Are you sure that you want to delete this job runner with all its jobs?')) {
                await api.deleteJobRunner(runner, store.state.auth.jwt);
                runners.value = await api.getJobRunners(store.state.auth.jwt);
            }
        }

        async function ping(runner: JobRunner) {
            // Set the ping in progress state for this runner
            pingInProgress[runner.id] = true;
            
            try {
                await api.pingJobRunner(runner, store.state.auth.jwt);
                runners.value = await api.getJobRunners(store.state.auth.jwt);
            } finally {
                // Clear the ping state after a short delay for visual feedback
                setTimeout(() => {
                    pingInProgress[runner.id] = false;
                }, 1000);
            }
        }
        return {
            layoutClass,
            getButtonClass,
            setLayout,
            layout,
            store,
            runners,
            setActivationState,
            editingRunnerId,
            editingRunnerName,
            nameInput,
            startEditingName,
            saveRunnerName,
            remove,
            ping,
            pingInProgress,
            deleteButton: {
                color: '#ff5959',
                textDecoration: 'none',
                gap: '4px',
                width: '16px',
                text: 'Remove',
                padding: '6px 10px'
            },
            expectedVersion,
            formatDate,
        };
    }
});