/**
 * Main Application Entry Point
 * 
 * This file initializes the Vue application and configures all plugins and global features:
 * - Vue 3 application instance
 * - Vuex store initialization
 * - Vue Router setup
 * - SignalR real-time communication
 * - Sentry error tracking
 * - Apple login integration
 * - Global filters and utilities
 */
import { createApp } from 'vue';
import VueAppleLogin from 'vue-apple-login';
import router from './router';
import store from './store';
import * as Sentry from '@sentry/vue';
import * as signalR from '@microsoft/signalr';
import excel from 'vue-excel-export';
import uploader from 'vue-simple-uploader';
import 'vue-simple-uploader/dist/style.css';
import App from './App.vue';
import { LoadingPlugin } from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import './assets/css/common-layout.css';

// Initialize SignalR connection for real-time updates
const connection = new signalR.HubConnectionBuilder().withUrl('/hub').build();

connection.start().catch((err) => console.log(err));

// Initialize the store first to load authentication state
store.dispatch('init').then(() => {
  // Create Vue application instance
  const app = createApp(App).use(store).use(router).use(LoadingPlugin);

  // Configure Sentry for error tracking
  Sentry.init({
    app,
    dsn: 'https://d3dde240464d47049e40665d08f8377b@o4504485054906368.ingest.sentry.io/4504576234815488',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  // Register file upload component
  app.use(uploader);

  // Configure Apple Sign-in
  app.use(VueAppleLogin, {
      clientId: 'com.lightfield.industries.capture.stage',
      //clientId: 'com.lightfield.industries.capture',
      scope: 'name email',
      redirectURI: 'https://staging-clients.plinth.it',
      state: '1',
      usePopup: true,
  });

  // Register global filters
  app.config.globalProperties.$filters = {
    dateFormat(date: Date) {
      return `${date.toLocaleDateString('en-US')}, ${date.toLocaleTimeString(
        'en-US'
      )}`;
    },
  };
  
  // Register Excel export functionality
  app.use(excel);
  
  // Make SignalR connection available throughout the app
  app.provide('connection', connection);
  
  // Mount the app to the DOM
  app.mount('#app');
});
