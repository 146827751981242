
import { defineComponent, ref, computed } from 'vue';
import { useForm, useField } from 'vee-validate';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { State } from '../store';
import api from '../api';

export default defineComponent({
  setup() {
    const route = useRoute();
    const store = useStore<State>();
    const router = useRouter();
    const errorMsg = ref('');
    const successMsg = ref('');

    // Use token from URL if available, otherwise use an empty string
    const initialToken = computed(() => {
      return route.params.emailVerificationToken as string || '';
    });

    // Setup validation with vee-validate
    const { handleSubmit } = useForm();
    const { value: token, errorMessage: tokenError } = useField('token', 
      (value) => {
        if (!value) return 'Verification token is required';
        if (value.length < 10) return 'Token is too short';
        return true;
      }, 
      { initialValue: initialToken.value }
    );

    const onSubmit = handleSubmit(async (values) => {
      try {
        await api.verifyemail(token.value);
        successMsg.value = 'Your email has been verified successfully!';
        errorMsg.value = '';
        
        // Redirect to login after a delay
        setTimeout(() => {
          router.push('/login');
        }, 3000);
      } catch (reason) {
        errorMsg.value = 'Failed to verify your email. Please check the token and try again.';
        successMsg.value = '';
      }
    });

    return {
      token,
      tokenError,
      onSubmit,
      errorMsg,
      successMsg
    };
  },
});
