import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelCheckbox as _vModelCheckbox, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "container projects-users__container" }
const _hoisted_2 = { class: "projects-users__heading" }
const _hoisted_3 = { class: "heading-btns" }
const _hoisted_4 = { class: "btn" }
const _hoisted_5 = { class: "brows-bar" }
const _hoisted_6 = { class: "projects-users__Search-box" }
const _hoisted_7 = { class: "input-box" }
const _hoisted_8 = { class: "projects-users__switch-layouts" }
const _hoisted_9 = { class: "table-responsive" }
const _hoisted_10 = {
  key: 0,
  class: "project-infos",
  style: {"background-color":"#212121"}
}
const _hoisted_11 = {
  key: 0,
  class: "text-center-td"
}
const _hoisted_12 = { class: "name-email-in-boxes-cell" }
const _hoisted_13 = {
  key: 0,
  class: "secondary-typography"
}
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "text-center-td flex-in-boxes role-cell" }
const _hoisted_16 = {
  key: 0,
  class: "project-box-itm secondary-typography"
}
const _hoisted_17 = { class: "text-center-td flex-in-boxes credits-cell" }
const _hoisted_18 = {
  key: 0,
  class: "project-box-itm secondary-typography"
}
const _hoisted_19 = { class: "text-center-td" }
const _hoisted_20 = { class: "text-center-td flex-in-boxes projects-cell" }
const _hoisted_21 = {
  key: 0,
  class: "project-box-itm secondary-typography"
}
const _hoisted_22 = { class: "active-cell text-center-td" }
const _hoisted_23 = { class: "toggle-btn btn" }
const _hoisted_24 = ["onUpdate:modelValue", "onChange"]
const _hoisted_25 = { class: "edit-delete-btns-cell flex-in-boxes" }
const _hoisted_26 = { class: "edit-delete-btns-cell-flex flex-in-boxes" }
const _hoisted_27 = ["onClick"]
const _hoisted_28 = {
  class: "edit-icon",
  viewBox: "0 0 16 16",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_29 = { "clip-path": "url(#clip0_120_4063)" }
const _hoisted_30 = ["stroke"]
const _hoisted_31 = ["stroke"]
const _hoisted_32 = ["onClick"]
const _hoisted_33 = ["stroke"]
const _hoisted_34 = ["stroke"]
const _hoisted_35 = ["stroke"]
const _hoisted_36 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_export_excel = _resolveComponent("export-excel")!
  const _component_AddNewUserModal = _resolveComponent("AddNewUserModal")!
  const _component_EditUserModal = _resolveComponent("EditUserModal")!
  const _component_UserAddedNotification = _resolveComponent("UserAddedNotification")!
  const _component_RUSureModal = _resolveComponent("RUSureModal")!
  const _component_UserRemovedNotification = _resolveComponent("UserRemovedNotification")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[6] || (_cache[6] = _createElementVNode("h2", null, "All Users", -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            class: "add-btn main-btn btn-has-icon btn",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openNewUser()))
          }, _cache[4] || (_cache[4] = [
            _createElementVNode("img", {
              class: "add-icon",
              src: "images/plus-icon.svg"
            }, null, -1),
            _createElementVNode("span", { class: "txt" }, "Add New User", -1)
          ])),
          _createElementVNode("button", _hoisted_4, [
            _createVNode(_component_export_excel, {
              data: _ctx.store.state.users.items,
              class: "add-project-btn main-btn btn-l btn-has-icon btn",
              style: {"background-color":"#000000","border-color":"#643be0"}
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createElementVNode("img", {
                  src: "images/filesvg1.png",
                  alt: "Export Icon",
                  style: {"vertical-align":"middle","margin-right":"10px","width":"20px"}
                }, null, -1),
                _createTextVNode(" Export Data ")
              ])),
              _: 1
            }, 8, ["data"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _cache[7] || (_cache[7] = _createElementVNode("svg", {
              class: "projects-users__Search-icon",
              viewBox: "0 0 19 19",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("circle", {
                cx: "8.4375",
                cy: "8.4375",
                r: "7.4375",
                stroke: "white",
                "stroke-width": "2"
              }),
              _createElementVNode("path", {
                d: "M13.5 13.5L18 18",
                stroke: "white",
                "stroke-width": "2"
              })
            ], -1)),
            _withDirectives(_createElementVNode("input", {
              class: "text-input",
              type: "text",
              placeholder: "Search",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchQuery) = $event))
            }, null, 512), [
              [_vModelText, _ctx.searchQuery]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("button", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setLayout('table'))),
            class: _normalizeClass(_ctx.getButtonClass('table'))
          }, _cache[8] || (_cache[8] = [
            _createElementVNode("svg", {
              class: "projects-users__switch-icon",
              width: "18",
              height: "18",
              viewBox: "0 0 18 18",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("path", {
                d: "M6.03696 3.07411H15.6666V4.55559H6.03696V3.07411ZM2.33325 2.70374H4.55547V4.92596H2.33325V2.70374ZM2.33325 7.88892H4.55547V10.1111H2.33325V7.88892ZM2.33325 13.0741H4.55547V15.2963H2.33325V13.0741ZM6.03696 8.25929H15.6666V9.74077H6.03696V8.25929ZM6.03696 13.4445H15.6666V14.926H6.03696V13.4445Z",
                fill: "white"
              })
            ], -1)
          ]), 2),
          _createElementVNode("button", {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setLayout('boxes'))),
            class: _normalizeClass(_ctx.getButtonClass('boxes'))
          }, _cache[9] || (_cache[9] = [
            _createElementVNode("svg", {
              class: "projects-users__switch-icon",
              width: "18",
              height: "18",
              viewBox: "0 0 18 18",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("path", {
                d: "M1.59253 3.07399C1.59253 2.87754 1.67057 2.68913 1.80949 2.55021C1.9484 2.41129 2.13681 2.33325 2.33327 2.33325H15.6666C15.8631 2.33325 16.0515 2.41129 16.1904 2.55021C16.3293 2.68913 16.4073 2.87754 16.4073 3.07399V14.9258C16.4073 15.1223 16.3293 15.3107 16.1904 15.4496C16.0515 15.5885 15.8631 15.6666 15.6666 15.6666H2.33327C2.13681 15.6666 1.9484 15.5885 1.80949 15.4496C1.67057 15.3107 1.59253 15.1223 1.59253 14.9258V3.07399ZM11.9629 14.1851H14.9259V3.81473H11.9629V14.1851ZM10.4814 3.81473H7.51846V14.1851H10.4814V3.81473ZM3.07401 3.81473V14.1851H6.03697V3.81473H3.07401Z",
                fill: "white"
              })
            ], -1)
          ]), 2)
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("table", {
          class: _normalizeClass(_ctx.layoutClass)
        }, [
          (_ctx.layout === 'table')
            ? (_openBlock(), _createElementBlock("thead", _hoisted_10, _cache[10] || (_cache[10] = [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, "Id"),
                  _createElementVNode("th", { class: "text-start-th" }, "Name"),
                  _createElementVNode("th", { class: "text-start-th" }, "Email"),
                  _createElementVNode("th", null, "Role"),
                  _createElementVNode("th", null, "Credit"),
                  _createElementVNode("th", null, "Runner"),
                  _createElementVNode("th", null, "Projects"),
                  _createElementVNode("th", null, "Active"),
                  _createElementVNode("th"),
                  _createElementVNode("th")
                ], -1)
              ])))
            : _createCommentVNode("", true),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredUsers, (user, idx) => {
              return (_openBlock(), _createElementBlock("tr", {
                class: "user-bar main-itm-ltr-space",
                key: idx
              }, [
                (_ctx.layout === 'table')
                  ? (_openBlock(), _createElementBlock("td", _hoisted_11, _toDisplayString(user.id + '  '), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("td", _hoisted_12, [
                  _createTextVNode(_toDisplayString(_ctx.displayUserName(user.userName)) + " ", 1),
                  (_ctx.layout === 'boxes')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(user.email), 1))
                    : _createCommentVNode("", true)
                ]),
                (_ctx.layout === 'table')
                  ? (_openBlock(), _createElementBlock("td", _hoisted_14, _toDisplayString(user.email), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("td", _hoisted_15, [
                  (_ctx.layout === 'boxes')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_16, "Role"))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", {
                    class: _normalizeClass(user.isAdmin ? 'editor-role role' : 'viewer-role role')
                  }, _toDisplayString(user.isAdmin ? 'Editor' : 'Viewer'), 3)
                ]),
                _createElementVNode("td", _hoisted_17, [
                  (_ctx.layout === 'boxes')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_18, "Credits"))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", null, _toDisplayString(user.credit), 1)
                ]),
                _createElementVNode("td", _hoisted_19, _toDisplayString(user.runnerName), 1),
                _createElementVNode("td", _hoisted_20, [
                  (_ctx.layout === 'boxes')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_21, "Projects"))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", null, _toDisplayString(user.totalProject), 1)
                ]),
                _createElementVNode("td", _hoisted_22, [
                  _createElementVNode("label", _hoisted_23, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      "onUpdate:modelValue": ($event: any) => ((user.isActive) = $event),
                      onChange: ($event: any) => (_ctx.store.dispatch('users/updateUser', user))
                    }, null, 40, _hoisted_24), [
                      [_vModelCheckbox, user.isActive]
                    ]),
                    _cache[11] || (_cache[11] = _createElementVNode("svg", {
                      class: "toggle-btn-icon",
                      viewBox: "0 0 54 29",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }, [
                      _createElementVNode("rect", {
                        y: "0.181885",
                        width: "54",
                        height: "28",
                        rx: "14",
                        fill: "#2e244d"
                      }),
                      _createElementVNode("circle", {
                        cx: "14",
                        cy: "14.1819",
                        r: "11",
                        fill: "#ffffff17"
                      })
                    ], -1))
                  ])
                ]),
                _createElementVNode("td", _hoisted_25, [
                  _createElementVNode("div", _hoisted_26, [
                    _createElementVNode("button", {
                      class: "edit-btn btn btn-has-icon",
                      onClick: ($event: any) => (_ctx.openEditUser(user)),
                      style: _normalizeStyle({
                    color: _ctx.editButton.color,
                    textDecoration: _ctx.editButton.textDecoration,
                    gap: _ctx.editButton.gap,
                    letterSpacing: _ctx.editButton.letterSpacing,
                  })
                    }, [
                      (_openBlock(), _createElementBlock("svg", _hoisted_28, [
                        _createElementVNode("g", _hoisted_29, [
                          _createElementVNode("path", {
                            d: "M7.33301 2.66663H2.66634C2.31272 2.66663 1.97358 2.8071 1.72353 3.05715C1.47348 3.3072 1.33301 3.64634 1.33301 3.99996V13.3333C1.33301 13.6869 1.47348 14.0261 1.72353 14.2761C1.97358 14.5262 2.31272 14.6666 2.66634 14.6666H11.9997C12.3533 14.6666 12.6924 14.5262 12.9425 14.2761C13.1925 14.0261 13.333 13.6869 13.333 13.3333V8.66663",
                            stroke: _ctx.editButton.color,
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round"
                          }, null, 8, _hoisted_30),
                          _createElementVNode("path", {
                            d: "M12.333 1.66665C12.5982 1.40144 12.9579 1.25244 13.333 1.25244C13.7081 1.25244 14.0678 1.40144 14.333 1.66665C14.5982 1.93187 14.7472 2.29158 14.7472 2.66665C14.7472 3.04173 14.5982 3.40144 14.333 3.66665L7.99967 9.99999L5.33301 10.6667L5.99967 7.99999L12.333 1.66665Z",
                            stroke: _ctx.editButton.color,
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round"
                          }, null, 8, _hoisted_31)
                        ]),
                        _cache[12] || (_cache[12] = _createElementVNode("defs", null, [
                          _createElementVNode("clipPath", { id: "clip0_120_4063" }, [
                            _createElementVNode("rect", {
                              width: "16",
                              height: "16",
                              fill: "white"
                            })
                          ])
                        ], -1))
                      ])),
                      _cache[13] || (_cache[13] = _createTextVNode(" Edit "))
                    ], 12, _hoisted_27),
                    _createElementVNode("button", {
                      class: "delete-btn btn-has-icon btn",
                      onClick: ($event: any) => (_ctx.confirmDelete(user)),
                      style: _normalizeStyle({
                    color: _ctx.deleteButton.color,
                    textDecoration: _ctx.deleteButton.textDecoration,
                    gap: _ctx.deleteButton.gap,
                  })
                    }, [
                      (_openBlock(), _createElementBlock("svg", {
                        class: "delete-icon",
                        style: _normalizeStyle({
                      width: _ctx.deleteButton.width,
                    }),
                        viewBox: "0 0 17 16",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }, [
                        _createElementVNode("path", {
                          d: "M2.96704 4H4.30037H14.967",
                          stroke: _ctx.deleteButton.color,
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }, null, 8, _hoisted_33),
                        _createElementVNode("path", {
                          d: "M6.30029 3.99992V2.66659C6.30029 2.31296 6.44077 1.97382 6.69082 1.72378C6.94087 1.47373 7.28 1.33325 7.63363 1.33325H10.3003C10.6539 1.33325 10.9931 1.47373 11.2431 1.72378C11.4932 1.97382 11.6336 2.31296 11.6336 2.66659V3.99992M13.6336 3.99992V13.3333C13.6336 13.6869 13.4932 14.026 13.2431 14.2761C12.9931 14.5261 12.6539 14.6666 12.3003 14.6666H5.63363C5.28 14.6666 4.94087 14.5261 4.69082 14.2761C4.44077 14.026 4.30029 13.6869 4.30029 13.3333V3.99992H13.6336Z",
                          stroke: _ctx.deleteButton.color,
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }, null, 8, _hoisted_34),
                        _createElementVNode("path", {
                          d: "M7.63379 7.33325V11.3333",
                          stroke: _ctx.deleteButton.color,
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }, null, 8, _hoisted_35),
                        _createElementVNode("path", {
                          d: "M10.3003 7.33325V11.3333",
                          stroke: _ctx.deleteButton.color,
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }, null, 8, _hoisted_36)
                      ], 4))
                    ], 12, _hoisted_32)
                  ])
                ])
              ]))
            }), 128))
          ])
        ], 2)
      ])
    ]),
    _createVNode(_component_AddNewUserModal, {
      startAddNewUserModal: _ctx.startAddNewUserModal,
      runners: _ctx.runners,
      onEditAddNewUserModalExistence: _ctx.EditAddNewUserModalExistence,
      onChangeUserAddedNotificationState: _ctx.changeUserAddedNotificationState,
      onAddUser: _ctx.addUser
    }, null, 8, ["startAddNewUserModal", "runners", "onEditAddNewUserModalExistence", "onChangeUserAddedNotificationState", "onAddUser"]),
    _createVNode(_component_EditUserModal, {
      startEditUserModal: _ctx.startEditUserModal,
      userToEdit: _ctx.userToEdit,
      runners: _ctx.runners,
      onExistEditUserModal: _ctx.existEditUserModal,
      onUpdateUser: _ctx.updateUser,
      onConfirmDelete: _ctx.confirmDelete
    }, null, 8, ["startEditUserModal", "userToEdit", "runners", "onExistEditUserModal", "onUpdateUser", "onConfirmDelete"]),
    _createVNode(_component_UserAddedNotification, {
      StartUserAddedNotification: _ctx.StartUserAddedNotification,
      onChangeUserAddedNotificationState: _ctx.changeUserAddedNotificationState
    }, null, 8, ["StartUserAddedNotification", "onChangeUserAddedNotificationState"]),
    _createVNode(_component_RUSureModal, {
      startRUSureModal: _ctx.startRUSureModal,
      onExistRUSureModal: _ctx.existRUSureModal,
      onConfirm: _ctx.deleteConfirmed,
      headingText: _ctx.userToDeleteName
    }, null, 8, ["startRUSureModal", "onExistRUSureModal", "onConfirm", "headingText"]),
    _createVNode(_component_UserRemovedNotification, {
      StartUserRemovedNotification: _ctx.StartUserRemovedNotification,
      removedItem: _ctx.userToDeleteName,
      onForceCloseRemovedNotification: _ctx.forceCloseRemovedNotification
    }, null, 8, ["StartUserRemovedNotification", "removedItem", "onForceCloseRemovedNotification"])
  ], 64))
}