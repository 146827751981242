import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "add-btn main-btn btn-has-icon btn" }
const _hoisted_2 = { class: "txt" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("img", {
      class: "add-icon",
      src: "images/plus-icon.svg"
    }, null, -1)),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.text), 1)
  ]))
}