import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sign-in" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "right side" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_apple_login = _resolveComponent("vue-apple-login")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "left side" }, [
        _createElementVNode("img", {
          class: "art",
          src: "images/art.png"
        })
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("img", {
          class: "logo",
          src: "images/logo-icon.png"
        }, null, -1)),
        _cache[1] || (_cache[1] = _createElementVNode("h4", { style: {"margin-bottom":"10px"} }, "Welcome", -1)),
        _createVNode(_component_vue_apple_login, {
          mode: "center-align",
          color: "white",
          border: true,
          type: "sign in",
          width: "300",
          height: "45",
          onSuccess: _ctx.onSuccess,
          onFailure: _ctx.onFailure,
          style: {"margin-top":"25px"}
        }, null, 8, ["onSuccess", "onFailure"])
      ])
    ])
  ]))
}