import { ActionContext } from 'vuex';
import { findById, patchArray, setArray, setItem } from './util';
import { State } from '.';
import { Project } from '@/models/Project';
import { InitProjectUploadResponseData } from '@/models/InitProjectUploadResponseData';
import { ApiResponse } from '@/models/ApiResponse';
import { ProjectUpload } from '@/models/ProjectUpload';
import { ProjectFinishData } from '@/models/ProjectFinishData';
import { InitProjectUploadRequestData } from '@/models/InitProjectUploadRequestData';
import { ProjectCropRequestData } from '@/models/ProjectCropRequestData';
import { ProjectStatus } from '../models/ProjectStatus';
import api from '../api';

/**
 * Project state interface
 * Stores the collection of projects
 */
export interface ProjectsState {
    /** Array of Project entities */
    items: Array<Project>;
}

/** Type alias for the Vuex action context */
type Context = ActionContext<ProjectsState, State>;

/**
 * Project Module for Vuex store
 * Manages projects data and related operations like creation, updating, and deletion
 */
export default {
    namespaced: true,
    state: () => ({
        items: Array<Project>()
    }),
    mutations: {
        /**
         * Sets the complete projects array in the state
         * @param state Current state 
         * @param projects New projects array to set
         */
        setProjects: function (state: ProjectsState, projects: Array<Project>) {
            state.items = setArray(state.items, projects);
        },
        
        /**
         * Patches the existing projects array with new project data
         * Updates matching projects and adds new ones
         * @param state Current state
         * @param projects Projects to patch into the state
         */
        patchProjects: function (state: ProjectsState, projects: Array<Project>) {
            patchArray(state.items, projects);
        },
        
        /**
         * Sets a single project in the state
         * Updates if exists, adds if new
         * @param state Current state
         * @param project Project to set
         */
        setProject: function (state: ProjectsState, project: Project) {
            setItem(state.items, project);
        }
    },
    actions: {
        /**
         * Fetches all projects from the API and updates the state
         * @param context Vuex action context
         * @returns Promise resolving to the array of projects
         */
        getProjects: async function (context: Context): Promise<Array<Project>> {
            const projects = await api.getProjects(context.rootState.auth.jwt);
            context.commit('setProjects', projects);
            return context.state.items;
        },
        
        /**
         * Creates a new project via the API
         * @param context Vuex action context
         * @param project Project to create
         * @returns Promise resolving to the created project
         */
        addProject: async function (context: Context, project: Project): Promise<Project> {
            project = await api.addProject(project, context.rootState.auth.jwt);
            context.commit('setProject', project);
            return findById<Project>(context.state.items, project.id) as Project;
        },
        
        /**
         * Updates an existing project via the API
         * @param context Vuex action context
         * @param project Project with updated fields
         * @returns Promise resolving to the updated project
         */
        updateProject: async function (context: Context, project: Project): Promise<Project> {
            project = await api.updateProject(project, context.rootState.auth.jwt);
            context.commit('setProject', project);
            return findById<Project>(context.state.items, project.id) as Project;
        },
        
        /**
         * Updates the status of a project via the API
         * @param context Vuex action context
         * @param project Project with updated status
         * @returns Promise resolving to the updated project
         */
        updateProjectStatus: async function (context: Context, project: Project): Promise<Project> {
            project = await api.updateProjectStatus(project, context.rootState.auth.jwt);
            context.commit('setProject', project);
            return findById<Project>(context.state.items, project.id) as Project;
        },
        
        /**
         * Initiates the cropping process for a project
         * @param context Vuex action context
         * @param project Project to crop
         * @returns Promise resolving to the updated project with Optimizing status
         */
        croppingProject: async function (context: Context, project: Project): Promise<Project> {
            const croppingdata: ProjectCropRequestData = {
                skipFramingProcess: true,
                isPostPurchaseEnabled: true,
                useAdjustedFile: false
            };
            await api.croppingProject(project, croppingdata, context.rootState.auth.jwt);
            project.status = ProjectStatus.Optimizing;
            context.commit('setProject', project);
            return findById<Project>(context.state.items, project.id) as Project;
        },
        
        /**
         * Deletes a project via the API
         * @param context Vuex action context
         * @param project Project to delete
         * @returns Promise resolving when deletion is complete
         */
        deleteProject: async function (context: Context, project: Project): Promise<void> {
            context.commit('setProjects', context.state.items.filter((i: Project) => i !== project));
            await api.deleteProject(project, context.rootState.auth.jwt);
        },
        
        /**
         * Deletes all projects via the API (admin operation)
         * @param context Vuex action context
         * @returns Promise resolving when deletion is complete
         */
        deleteAllProject: async function (context: Context): Promise<void> {
            context.commit('setProjects', context.state.items.filter((i: Project) => i.id == 0));
            await api.deleteAllProject(context.rootState.auth.jwt);
        },
        
        /**
         * Deletes multiple selected projects by their IDs
         * @param context Vuex action context
         * @param projectIds Comma-separated string of project IDs to delete
         * @returns Promise resolving when deletion is complete
         */
        deleteSelectedProject: async function (context: Context, projectIds : string): Promise<void> {
            //context.commit('setProjects', context.state.items.filter((i: Project) => i.id == 0));
            await api.deleteSelectedProject(projectIds, context.rootState.auth.jwt);
        },

        /**
         * Deletes multiple selected jobs by their IDs
         * @param context Vuex action context
         * @param jobIds Comma-separated string of job IDs to delete
         * @returns Promise resolving when deletion is complete
         */
        deleteSelectedJob: async function (context: Context, jobIds: string): Promise<void> {
            //context.commit('setProjects', context.state.items.filter((i: Project) => i.id == 0));
            await api.deleteSelectedJob(jobIds, context.rootState.auth.jwt);
        },
        
        //projectUploads: async function (context: Context, project: Project): Promise<ProjectUpload> {
        //    return await api.projectUploads(project, context.rootState.auth.jwt);
        //},
        
        /**
         * Uploads an image for a project
         * @param context Vuex action context
         * @param projectUpload Project upload data
         * @returns Promise resolving to true when upload is complete
         */
        uploadImage: async function (context: Context, projectUpload: ProjectUpload): Promise<boolean> {
            await api.uploadImage(projectUpload, context.rootState.auth.jwt);
            return true;
        },

        //finishAndRun: async function (context: Context, requestData: ProjectFinishData): Promise<void> {
        //    return await api.finishAndRunWithBounding(requestData, context.rootState.auth.jwt);
        //},

        /**
         * Finishes the upload process and starts the 3D processing job
         * @param context Vuex action context
         * @param requestData Project finish data including processing parameters
         * @returns Promise resolving when the job is started
         * @throws Error if the operation fails
         */
        finishAndRun: async function (context: Context, requestData: ProjectFinishData): Promise<void> {
            try {
                const result = await api.finishAndRunWithBounding(requestData, context.rootState.auth.jwt);
                return result;
            } catch (error) {
                throw new Error('Failed to finish and run');
            }
        },

        /**
         * Initializes the image upload process for a project
         * @param context Vuex action context
         * @param initProjectUploadRequestData Upload initialization data
         * @returns Promise resolving to the API response with upload details
         */
        uploadImageInitProcess: async function (context: Context, initProjectUploadRequestData: InitProjectUploadRequestData): Promise<ApiResponse<InitProjectUploadResponseData>> {
            return await api.uploadImageInitProcess(initProjectUploadRequestData, context.rootState.auth.jwt);
        },

        /**
         * Initializes the ZIP file upload process for a project
         * @param context Vuex action context
         * @param initProjectUploadRequestData Upload initialization data
         * @returns Promise resolving to the API response with upload details
         */
        uploadZipInitProcess: async function (context: Context, initProjectUploadRequestData: InitProjectUploadRequestData): Promise<ApiResponse<InitProjectUploadResponseData>> {
            return await api.uploadZipInitProcess(initProjectUploadRequestData, context.rootState.auth.jwt);
        },
    }
}