
/**
 * Main Header Component
 * 
 * Renders the application header with navigation links, theme controls, and user menu.
 * The component is only shown when a user is logged in. Includes conditional
 * admin-only navigation links and responsive mobile menu functionality.
 */
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MainHeader',
  
  /**
   * Component props
   * @property {Object} store - Vuex store instance containing auth state
   */
  props: ['store'],
  
  /**
   * Events emitted by this component:
   * - hamburgerClicked: When the mobile menu button is clicked
   * - switchdarktheme: When the dark theme button is clicked
   * - switchlighttheme: When the light theme button is clicked
   */
});
