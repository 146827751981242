import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MainHeader, {
      store: _ctx.store,
      onHamburgerClicked: _ctx.hamburgerClicked,
      onSwitchdarktheme: _ctx.switchdarktheme,
      onSwitchlighttheme: _ctx.switchlighttheme
    }, null, 8, ["store", "onHamburgerClicked", "onSwitchdarktheme", "onSwitchlighttheme"]),
    _cache[0] || (_cache[0] = _createElementVNode("noscript", null, [
      _createElementVNode("strong", null, "We're sorry but Plinth Client Portal doesn't work properly without JavaScript enabled. Please enable it to continue.")
    ], -1)),
    _createVNode(_component_router_view)
  ], 64))
}