import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-wrapper credits has-side-bar" }
const _hoisted_2 = { class: "content main-content content-hasnt-pd-mobile" }
const _hoisted_3 = { class: "second-h3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, "Your Credits: " + _toDisplayString(_ctx.store.state.auth.user.credit), 1),
      _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"boxes\"><div class=\"box\"><h5>Buy 1 Credit</h5><p>You can use the credit to purchase the models</p><button class=\"buy-btn main-btn btn\"> Buy for $2.99 </button></div><div class=\"box\"><h5>Buy 5 Credits</h5><p>You can use the credit to purchase the models</p><button class=\"buy-btn main-btn btn\"> Buy for $6.99 </button></div><div class=\"box\"><h5>Buy 10 Credits</h5><p>You can use the credit to purchase the models</p><button class=\"buy-btn main-btn btn\"> Buy for $12.99 </button></div></div>", 1))
    ])
  ]))
}