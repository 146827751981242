import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = {
  key: 0,
  class: "alert alert-danger",
  role: "alert"
}
const _hoisted_4 = {
  key: 1,
  class: "alert alert-success",
  role: "alert"
}
const _hoisted_5 = {
  key: 2,
  class: "spinner-border text-success",
  role: "status"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", null, [
      _createElementVNode("div", _hoisted_2, [
        _cache[2] || (_cache[2] = _createElementVNode("label", {
          for: "email",
          class: "form-label"
        }, "Email address", -1)),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
          type: "email",
          class: "form-control",
          id: "email",
          placeholder: "name@example.com"
        }, null, 512), [
          [_vModelText, _ctx.email]
        ]),
        _createElementVNode("span", null, _toDisplayString(_ctx.emailError), 1)
      ]),
      (_ctx.errorMsg)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.errorMsg), 1))
        : _createCommentVNode("", true),
      (_ctx.successMsg)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.successMsg), 1))
        : _createCommentVNode("", true),
      (_ctx.submitting)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[3] || (_cache[3] = [
            _createElementVNode("span", { class: "visually-hidden" }, "Loading...", -1)
          ])))
        : (_openBlock(), _createElementBlock("input", {
            key: 3,
            class: "btn btn-primary",
            type: "button",
            value: "Submit",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
          }))
    ])
  ]))
}