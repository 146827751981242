import { createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "form-group mb-3" }
const _hoisted_3 = {
  key: 0,
  class: "invalid-feedback"
}
const _hoisted_4 = {
  key: 0,
  class: "alert alert-danger mt-3",
  role: "alert"
}
const _hoisted_5 = {
  key: 1,
  class: "alert alert-success mt-3",
  role: "alert"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("p", null, "Verify your email:", -1)),
    _createElementVNode("form", {
      onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _cache[2] || (_cache[2] = _createElementVNode("label", { for: "token" }, "Verification Token", -1)),
        _withDirectives(_createElementVNode("input", {
          id: "token",
          type: "text",
          class: _normalizeClass(["form-control", { 'is-invalid': _ctx.tokenError }]),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.token) = $event))
        }, null, 2), [
          [_vModelText, _ctx.token]
        ]),
        (_ctx.tokenError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.tokenError), 1))
          : _createCommentVNode("", true)
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("input", {
        class: "btn btn-primary",
        type: "submit",
        value: "Verify Email"
      }, null, -1)),
      (_ctx.errorMsg)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.errorMsg), 1))
        : _createCommentVNode("", true),
      (_ctx.successMsg)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.successMsg), 1))
        : _createCommentVNode("", true)
    ], 32)
  ]))
}