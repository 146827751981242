import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "main-wrapper credits has-side-bar has-footer-btns" }
const _hoisted_2 = { class: "content main-content content-hasnt-pd-mobile" }
const _hoisted_3 = { class: "container-fluid" }
const _hoisted_4 = {
  class: "table users-table",
  style: {"table-layout":"fixed !important","overflow":"hidden","overflow-wrap":"break-word"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("table", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("colgroup", null, [
            _createElementVNode("col", { style: {"width":"25%"} }),
            _createElementVNode("col", { style: {"width":"25%"} }),
            _createElementVNode("col", { style: {"width":"25%"} }),
            _createElementVNode("col", { style: {"width":"25%"} })
          ], -1)),
          _cache[1] || (_cache[1] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", { scope: "col" }, "Time"),
              _createElementVNode("th", { scope: "col" }, "Type"),
              _createElementVNode("th", { scope: "col" }, "Message"),
              _createElementVNode("th", { scope: "col" }, "Info")
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.records, (record, idx) => {
              return (_openBlock(), _createElementBlock("tr", { key: idx }, [
                _createElementVNode("td", null, _toDisplayString(record.time), 1),
                _createElementVNode("td", null, _toDisplayString(record.type), 1),
                _createElementVNode("td", null, _toDisplayString(record.message), 1),
                _createElementVNode("td", null, _toDisplayString(record.info), 1)
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}