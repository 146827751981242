import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("main", null, [
    _cache[1] || (_cache[1] = _createElementVNode("img", {
      class: "main-vector",
      src: "images/main-Vector.svg"
    }, null, -1)),
    _cache[2] || (_cache[2] = _createElementVNode("h1", null, [
      _createElementVNode("span", { class: "cntrst" }, "Transform"),
      _createTextVNode(),
      _createElementVNode("br"),
      _createTextVNode(" your still photography into "),
      _createElementVNode("span", { class: "cntrst" }, "immersive 3D art")
    ], -1)),
    _cache[3] || (_cache[3] = _createElementVNode("p", null, "Plinth creates accurate 3D digital versions of your original artwork, allowing viewers to experience it in a whole new way", -1)),
    _createVNode(_component_router_link, {
      to: '/newproject',
      style: {"text-decoration-line":"none !important"}
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("button", { class: "get-started btn" }, [
          _createElementVNode("span", null, "Get started")
        ], -1)
      ])),
      _: 1
    }),
    _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"recs\"><div class=\"left col\"><div class=\"rec rec-left-top\"></div><div class=\"rec rec-left-bottom\"></div></div><div class=\"right col\"><div class=\"rec rec-right-top\"></div><div class=\"rec rec-right-btm\"></div></div></div>", 1))
  ]))
}